import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Container, Message, Segment, Header, Button, Divider, Form, Card } from 'semantic-ui-react';

import axios from 'axios';


function OfferCard({ offer: { title, description, business: { name } } }) { 
  return (
    <Card centered>
      <Card.Content>
        <Card.Header>{ title }</Card.Header>
        <Card.Meta>{ name }</Card.Meta>
        <Card.Description>
          { description }
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

function RedeemForm() {
  const queryClient = useQueryClient();

  const redeemMutation = useMutation(
    (params) => axios.post('/api/coupons/redeem/', params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('coupons');
      }
    }
  );

  const [couponCode, setCouponCode] = useState('');

  const coupon = useQuery(['coupon', { couponCode }],
    () => axios.get(`/api/coupons/verify/`, { params: { coupon_code: couponCode } }).then(({data}) => data),
    { retry: false, enabled: false }
  );

  const handleFindOffer = () => {
    if (!couponCode) return;
    redeemMutation.reset();

    coupon.remove(); // need to remove first to make sure 404's return null
    coupon.refetch();
  };

  const handleReset = () => {
    redeemMutation.reset();
    coupon.remove();

    setCouponCode('');
  };

  const handleSubmit = () => {
    redeemMutation.mutate({ coupon_code: couponCode }, {
      // onSuccess: () => setTimeout(handleReset, 5000)
    });
  };

  const isRedeemed = !!(coupon.data?.redeemed_at);
  const isExpired = !!(coupon.data?.is_expired);
  const isInactive = !!(coupon.data?.is_inactive);
  const isValid = !isRedeemed && !isExpired && !isInactive;

  let invalidCouponText;
  if (isRedeemed) {
    const redeemedAt = new Date(coupon.data?.redeemed_at).toLocaleString('el');
    invalidCouponText = `Το κουπόνι έχει ήδη εξαργυρωθεί την ${redeemedAt}.`;
  } else if (isExpired) {
    const expiredAt = new Date(coupon.data?.expired_at).toLocaleDateString('el');
    invalidCouponText = `Το κουπόνι ελήξε την ${expiredAt}.`;
  } else if (isInactive) {
    const activatedAt = new Date(coupon.data?.activated_at).toLocaleString('el');
    invalidCouponText = `Το κουπόνι δεν έχει ενεργοποιηθεί ακόμα, μπορείτε να το επιβεβαιώστε μετά την ${activatedAt}.`;
  }

  const pageTitle = 'Εξαργύρωση κουπονιού';
  return (<>
    <Helmet title={pageTitle} />
    <Container textAlign="center">
      <Header as="h1" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <Segment>
        <Form onSubmit={handleFindOffer} error={coupon.isError} success={coupon.isSuccess}>
          <Form.Group grouped>
            <Form.Input size="huge" placeholder="Κωδικός κουπονιού"
              value={couponCode}
              onChange={(e, {value}) => setCouponCode(value)}
            />
            <Form.Button floated="right" primary disabled={!couponCode}>Αναζήτηση</Form.Button>
          </Form.Group>

          <Divider hidden clearing />
          <Message error header="Σφάλμα κατά την αναζήτηση του κουπονιού, παρακαλώ προσπαθήστε ξανά." />

        </Form>
      </Segment>

      { coupon.data &&
          ( isValid ? 
            <Segment>
              <Header as="h3" textAlign="center" content="Βρέθηκε ενεργό κουπόνι για την προσφορά:"/>
              
              <OfferCard offer={coupon.data.offer} />
              <Button onClick={handleSubmit} floated="right" positive disabled={!couponCode}>Εξαργύρωση κουπονιού</Button>
              <Divider hidden clearing />

              { redeemMutation.isSuccess &&
                <Message onDismiss={handleReset} success header="Η εξαργύρωση του κουπονιού ολοκληρώθηκε επιτυχώς" />
              }

            </Segment>
            :
            <Segment>
              <Header as="h3" textAlign="center" content="Βρέθηκε κουπόνι για την προσφορά:"/>
              <OfferCard offer={coupon.data.offer} />

              <Message error header={invalidCouponText} />
            </Segment>
          )
      }

    </Container>
  </>);
}

export default RedeemForm;
