import { Link, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Card, Container, Loader, Message, Segment, Header, Button, Divider, Modal } from 'semantic-ui-react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';

import useProfile from '../queries/useProfile';
import { useCoupon } from '../queries/coupons';

import PointsBalance from './PointsBalance';

import axios from 'axios';


function CouponCard({ linkTo, coupon: { id, code, expired_at, is_inactive, activated_at, offer: { title, description, business: { name } } } }) {
  expired_at = expired_at && new Date(expired_at);
  activated_at = activated_at && new Date(activated_at);
  return (
    <Card as={linkTo ? Link : undefined} to={linkTo} centered>
      <Card.Content>
        <Segment size="huge" inverted color="green" textAlign="center">
          <strong>{ code }</strong>
        </Segment>
        <Card.Header>{ title }</Card.Header>
        <Card.Meta>{ name }</Card.Meta>
        <Card.Description>
          { description }
        </Card.Description>
      </Card.Content>
      { !is_inactive && expired_at && 
        <Card.Content extra>
          Μπορείτε να εξαργυρώσετε το κουπόνι μέχρι την {expired_at.toLocaleDateString('el')}
        </Card.Content>
      }
      { is_inactive && activated_at && 
        <Card.Content extra>
          Το κουπόνι θα ενεργοποιηθεί την {activated_at.toLocaleDateString('el')}.
        </Card.Content>
      }
    </Card>
  );
}

// Register Fonts
Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

Font.register({ family: 'GFS Neohellenic', fonts: [
  { src: `${process.env.PUBLIC_URL}/GFSNeohellenic-Regular.ttf` },
  { src: `${process.env.PUBLIC_URL}/GFSNeohellenic-Bold.ttf`, fontWeight: 'bold' },
]});

Font.register({ family: 'Noto Sans', fonts: [
  { src: `${process.env.PUBLIC_URL}/NotoSans-Regular.ttf` },
  { src: `${process.env.PUBLIC_URL}/NotoSans-Bold.ttf`, fontWeight: 'bold' },
]});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Noto Sans',
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'underline',
    marginTop: 48,
  },
  code: {
    fontSize: 48,
    textAlign: 'center',
    marginVertical: 24,
  },
  expiration: {
    width: '100%',
    border: '2px solid black',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 4,
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 8,
  },
  offer: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 36,
  },
  vendor: {
    marginTop: 48,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
  },
  fields: {
    fontWeight: 'bold',
    alignItems: 'flex-end',
    marginRight: 4,
  },
  footer: {
    borderTop: '1px solid black',
    paddingTop: 12,
    position: 'absolute',
    bottom: 35,
    left: 35,
    right: 35,
    fontSize: 12,
    textAlign: 'center',
  },
  links: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});


function CouponPrint({ coupon: {code, offer, expired_at} }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ alignItems: 'center' }}>
          <Image src={`${process.env.PUBLIC_URL}/coupon_logo.png`} style={{width: '50%'}} />
        </View>
        <View>
          <Text style={styles.title}>- ΚΟΥΠΟΝΙ ΕΠΙΒΡΑΒΕΥΣΗΣ -</Text>
          <Text style={styles.code}>{code}</Text>

          <Text style={styles.body}>Με χρήση αυτού του κωδικού έχετε:</Text>

          <Text style={styles.offer}>{offer.title}</Text>

          { expired_at &&
            <Text style={styles.expiration}>
              Προσοχή: Αυτό το κουπόνι επιβράβευσης ισχύει ως την{'\n'}{new Date(expired_at).toLocaleDateString('el')}
            </Text>
          }

          <View style={styles.vendor}>
            <View style={styles.fields}>
              <Text>Κατάστημα:</Text>
              <Text>Διεύθυνση:</Text>
              <Text>Δήμος:</Text>
              <Text>Τηλέφωνο:</Text>
            </View>
            <View style={styles.values}>
              <Text>{offer.business.name || '-'}</Text>
              <Text>{offer.business.address || '-'}</Text>
              <Text>{offer.business.municipality_name?.replace(/Δήμος ?/g, '') || '-'}</Text>
              <Text>{offer.business.telephone || '-'}</Text>
            </View>
          </View>

        </View>

        <View style={styles.footer}>
          <Text>Ομάδα Zero Waste της ΔΙΑΔΥΜΑ ΑΕ</Text>
          <Text>6ο χλμ Κοζάνης-Πτολεμαΐδας</Text>
          <Text>Τηλ: 24610 45531,3</Text>
          <Text>Website: diadyma.gr</Text>
          <Text>e-mail: zerowaste@diadyma.gr</Text>

          <View style={styles.links}>
            <Text>https://reuse.diadyma.gr/</Text>
            <Text>https://cookingoils.diadyma.gr/</Text>
            <Text>https://rewards.diadyma.gr/</Text>
          </View>

        </View>
      </Page>
    </Document>
  );
}


function Coupon({ onCouponChanged, ...props}) {
  const { couponId } = useParams();
  const coupon = useCoupon(couponId);

  const mutation = useMutation(
    () => axios.post(`/api/coupons/${couponId}/cancel/`),
    { onSuccess: onCouponChanged }
  );

  const canCancel = coupon.data?.is_inactive;

  return (
    <Modal size="tiny" open {...props}>
      <Modal.Header>Λεπτομέρειες κουπονιού</Modal.Header>
      <Modal.Content>
        { coupon.data && <CouponCard coupon={coupon.data} /> }
        {/* { coupon.data && <Modal open content={
            <PDFViewer height={600} width={600}>
              <CouponPrint coupon={coupon.data} />
            </PDFViewer>
          } />
        } */}
      </Modal.Content>
      <Modal.Actions>

        { canCancel &&
          <Button negative
            disabled={mutation.isLoading}
            loading={mutation.isLoading}
            onClick={() => mutation.mutate()}
            content="Ακύρωση κουπονιού"
          />
        }

        { coupon.data && !canCancel &&
          <Button primary icon="print" content="Εκτύπωση κουπονιού" 
            as={PDFDownloadLink}
            document={<CouponPrint coupon={coupon.data} />}
            fileName={`coupon_${coupon.data.code}`} 
          />
        }

        <Button onClick={props.onClose}>Επιστροφή</Button>
      </Modal.Actions>
    </Modal>
  );
}


function InactiveCoupons() {
  const match = useRouteMatch();

  const { isLoading, error, data } = useQuery('inactiveCoupons', () =>
    axios.get('/api/coupons/', { params: { status: 'inactive', ordering: '-created_at', page: 'all' }}).then(({data}) => data)
  );

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      <Divider section />
      <Segment basic>
        <Header as="h2" textAlign="center"
          content="Κουπόνια σε επεξεργασία"
          subheader="Μπορείτε να τα ακυρώσετε οποιαδήποτε στιγμή πριν ενεργοποιηθούν"
        />
      </Segment>

      { isLoading && <Loader active /> }
      { error && <Message error content="Κάτι πήγε στραβά!" /> }

      { data && 
        <Card.Group>
          { data.map((coupon) =>
              <CouponCard key={coupon.id} id={coupon.id} linkTo={`${match.url}/${coupon.id}`} coupon={coupon} />
            )
          }
        </Card.Group>
      }

    </>
  );
}


function Coupons() {
  const match = useRouteMatch();
  const history = useHistory();

  const queryClient = useQueryClient();

  const { refetch: refetchProfile } = useProfile();

  const { isLoading, error, data } = useQuery('validCoupons', () =>
    axios.get('/api/coupons/', { params: { status: 'valid', ordering: '-created_at', page: 'all' }}).then(({data}) => data)
  );

  const handleCouponChanged = () => {
    refetchProfile();
    queryClient.invalidateQueries('inactiveCoupons'); // only inactive ones are affected by a new coupon / coupon cancellation
    history.push(match.url);
  }

  const handleCouponClose = () => {
    history.push(match.url);
  };

  const pageTitle = 'Τα κουπόνια μου';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />

      <PointsBalance />

      <Segment basic textAlign="center" placeholder={isLoading} loading={isLoading}>
        { data?.length > 0 && <>
          <Header as="h2">Ενεργά Κουπόνια</Header>
          <Card.Group>
            { data.map((coupon) =>
                <CouponCard key={coupon.id} id={coupon.id} linkTo={`${match.url}/${coupon.id}`} coupon={coupon} />
              )
            }
          </Card.Group>
        </> }

        { data?.length === 0 && <>
            <Message compact size="large" warning>
              <Message.Content>
                <Message.Header>Δεν υπάρχουν διαθέσιμα ενεργά κουπόνια.</Message.Header>
                Μπορείτε να δείτε τις προσφορές <Link to="/">εδώ</Link>.
              </Message.Content>
            </Message>
          </>
        }

        { error && <Message compact size="large" error header="Κάτι πήγε στραβά!" content="Οι πληροφορίες για τα ενεργά κουπόνια δεν είναι διαθέσιμες." /> }
      </Segment>

      <InactiveCoupons />

      <Route path={`${match.path}/:couponId`}>
        <Coupon dimmer="inverted" onClose={handleCouponClose} onCouponChanged={handleCouponChanged} />
      </Route>

      <Divider hidden />
      
    </Container>
  );
}

export default Coupons;
