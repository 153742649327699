import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Modal, Message, Button } from 'semantic-ui-react';

import axios from 'axios';

function ActivateAccount() {
  const {uid, token} = useParams();

  const activate = useMutation(
    async () => {
      try {
        const { data } = await axios.post('/api/auth/users/activation/', { uid, token });
        return data;
      } catch (error) {
        throw new Error(JSON.stringify(error.response.data));
      }

    }
  );

  // activate immediately
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => activate.mutate(), []);

  return (
    <Modal open size="small" dimmer="inverted" centered={false}>
      <Modal.Header content="Ενεργοποίηση λογαριασμού" />
      <Modal.Content>
        { activate.isError && <Message error header="Αποτυχία ενεργοποίησης λογαριασμου." content="Πιθανόν να είναι ήδη ενεργοποιημένος. Εναλλακτικά, ξαναπροσπαθήστε αργότερα." /> }
        { activate.isSuccess && <Message success header="Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς!" content="Μπορείτε να συνδεθείτε επιλέγοντας ΣΥΝΔΕΣΗ ΚΑΤΑΣΤΗΜΑΤΟΣ" /> }
      </Modal.Content>
      <Modal.Actions>
        { activate.isError && <Button content="Επανάληψη" onClick={() => activate.mutate()} /> }

          <Button primary content={ activate.isSuccess ? 'OK': 'Επιστροφή' } as={Link} to="/" replace />
      </Modal.Actions>
    </Modal>
  );
}

export default ActivateAccount;
