import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Form, Header, Grid, Button, Message, Divider, Container, Segment } from 'semantic-ui-react';

import { ScrollToHere } from '@zerowaste/components';

import axios from 'axios';


function SignupErrorMessage({ keywords, ...props}) {
  const messageLines = [], messageItems = [];
  if (keywords?.includes('municipality required')) {
    messageLines.push('Παρακαλώ συμπληρώστε το πεδίο "Δήμος".');
  }

  if (keywords?.includes('email exists')) {
    messageLines.push(
      'Υπάρχει ήδη λογαριασμός που χρησιμοποιεί αυτήν την διεύθυνση email.',
      <>Μπορείτε να συνδεθείτε με την επιλογή <Link to="/vendor">ΣΥΝΔΕΣΗ ΚΑΤΑΣΤΗΜΑΤΟΣ</Link></>,
    );
  }
  if (keywords?.includes('invalid email')) {
    messageLines.push('Εισάγετε μια έγκυρη διεύθυνση email.');
  }

  let passwordError = false;
  if (keywords?.includes('password mismatch')) {
    messageLines.push('Η επαλήθευση συνθηματικού δεν ταιριάζει με το συνθηματικό.');
    passwordError = true;
  }

  if (keywords?.includes('short password')) {
    messageItems.push('Αυτό το συνθηματικό είναι πολύ μικρό. Πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.')
    passwordError = true;
  }
  if (keywords?.includes('common password')) {
    messageItems.push('Πολύ κοινό συνθηματικό.')
    passwordError = true;
  }
  if (keywords?.includes('numeric password')) {
    messageItems.push('Αυτό το συνθηματικό αποτελείται μόνο απο αριθμούς.')
    passwordError = true;
  }
  if (keywords?.includes('similar email password')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με τη διεύθυνση email.');
    passwordError = true;
  }
  let header = 'Αποτυχία εγγραφής';
  if (!messageLines.length && passwordError) {
    header = 'Μη αποδεκτό συνθηματικό';
  }

  return (
    <Message {...props} error>
      <Message.Header>{ header }</Message.Header>
      <Divider />
      { messageLines.length > 0 && 
        <p>
          { messageLines.map((content, index) => <div key={index}>{content}</div> ) }
        </p>
      }
      { messageItems.length > 0 && <Message.List items={messageItems} /> }
    </Message>
  );
}

function VendorSignup() {
  const [signupParams, setSignupParams] = useState({});
  const handleChange = ({ target: { name, value} }) => {
    setSignupParams((prevParams) => ({...prevParams, [name]: value }));
  }

  const [businessParams, setBusinessParams] = useState({});
  const handleBusinessChange = ({ target: { name, value} }) => {
    setBusinessParams((prevParams) => ({...prevParams, [name]: value }));
  };
  const handleSelectionChange = (e, {name, value}) => {
    setBusinessParams((prevParams) => ({...prevParams, [name]: value }));
  };

  const userMutation = useMutation(
    async () => {
      if (!businessParams.municipality) {
        throw new Error('municipality required');
      }

      if (!signupParams.password || signupParams.password !== signupParams.re_password) {
        throw new Error('password mismatch');
      }

      try {
        const { data } = await axios.post('/api/auth/users/', {
          ...signupParams,
          username: signupParams.email, // fix django requirement
          business: businessParams
        });
        return data;
      } catch (error) {
        // console.log(JSON.stringify(error.response.data));

        const emailErrors = error.response.data['email'];
        if (emailErrors?.includes('user with this email address already exists.')) {
          // just simplify message, include some keywords / phrases to render the proper message in UI
          throw new Error('email exists');
        }
        if (emailErrors?.includes('Enter a valid email address.')) {
          throw new Error('invalid email');
        }

        const passwordErrors = error.response.data['password'];
        if (passwordErrors) {
          let keywords = "";
          if (passwordErrors.includes('This password is too short. It must contain at least 8 characters.')) {
            keywords += 'short password ';
          }
          if (passwordErrors.includes('This password is too common.')) {
            keywords += 'common password ';
          }
          if (passwordErrors.includes('This password is entirely numeric.')) {
            keywords += 'numeric password ';
          }
          if (passwordErrors.includes('The password is too similar to the email address.')) {
            keywords += 'similar email password';
          }

          throw new Error(keywords);
        }

        throw new Error(JSON.stringify(error.response.data));
      }

    }
  );

  const handleSubmit = () => userMutation.mutate();

  const { data: municipalities } = useQuery('municipalities', () => axios.get('/api/municipalities/').then(({data}) => {
    // transform to options format
    return data.map((m) => ({ value: m.id, text: m.name }));
  }), { initialData: [] });

  if (userMutation.isSuccess) {
    return (
      <Container text textAlign="center" lang="el">
        <ScrollToHere />
        <Divider hidden />
        <Message>
          <Message.Header>
            Τα στοιχεία του λογαριασμού καταστήματός σας έχουν αποθηκευτεί
          </Message.Header>
          <Message.Content>
            <Divider />

            <p>Ευχαριστούμε για το ενδιαφέρον στην πλατφόρμα μας.</p>

            <p>Για να ολοκληρωθεί η εγγραφή του καταστήματός σας μένει μόνο να επιβεβαιώσετε την διεύθυνση email που μας δώσατε για την σύνδεση στην πλατφόρμα.</p>
            
            <p>Στην διεύθυνση αυτή σας έχουμε στείλει ένα μήνυμα με οδηγίες για την ολοκλήρωση της διαδικασίας.</p>

            <Divider />

            <Button as={Link} to="/" content="Επιστροφή" size="large" />
          </Message.Content>
        </Message>
        <Divider hidden />
      </Container>
    );
  }

  return (
    <Form size="large" onSubmit={handleSubmit} error={userMutation.isError} style={{ marginTop: '-2rem' }}>
      <Grid container stackable lang="el" as={Segment}>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign="center" as="h2" content="Εγγραφή καταστήματος στην πλατφόρμα εξαργύρωσης της ΔΙΑΔΥΜΑ" />          
            <Divider fitted />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Divider horizontal content="Στοιχεια καταστηματος" />

            <Form.Input required
              label='Επωνυμία επιχείρησης'
              name='name'
              onChange={handleBusinessChange}
            />

            <Form.Dropdown required selection
              label="Δήμος"
              name="municipality"
              value={businessParams.municipality || null}
              onChange={handleSelectionChange}
              options={municipalities}
            />

            <Form.TextArea required rows={2}
              label='Διεύθυνση'
              name='address'
              onChange={handleBusinessChange}
            />

            <Form.Input required
              label='Τηλέφωνο'
              name='telephone'
              input={{ inputMode: 'tel' }}
              onChange={handleBusinessChange}
            />

          </Grid.Column>
          
          <Grid.Column >
            <Divider horizontal content="Στοιχεια συνδεσης" />

            <Form.Input required
              label='Διεύθυνση email'
              name='email'
              input={{ inputMode: 'email' }}
              onChange={handleChange}
            />

            <Form.Input required
              label='Συνθηματικό'
              type='password'
              name='password'
              onChange={handleChange}
            />

            <Form.Input required
              label='Επαλήθευση συνθηματικού'
              type='password'
              name='re_password'
              onChange={handleChange}
            />

            <Divider hidden />
            
          </Grid.Column>

        </Grid.Row>

        <Grid.Row centered>
            <Button size="large" primary loading={userMutation.isLoading}>
              Εγγραφή
            </Button>

            <Button size="large" type="button" as={Link} to="/">
              Επιστροφή
            </Button>
        </Grid.Row>

        <Grid.Row centered>
          <Segment basic>
            <SignupErrorMessage keywords={userMutation.error?.message} />
          </Segment>
        </Grid.Row>

      </Grid>
      <Divider hidden />
    </Form>
  );
}

export default VendorSignup;
