import { Link, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Card, Container, Loader, Message, Header, Button, Divider, Modal, Label, Icon } from 'semantic-ui-react';

import axios from 'axios';

import { PopupOnMutationError } from '@zerowaste/components';

import useProfile from '../queries/useProfile';
import { useOffer, useOffers } from '../queries/offers';

import PointsBalance from './PointsBalance';


function OfferCard({ vendor, linkTo, offer: { title, description, required_points, days_until_expiration, hidden, business }={}, ...props}) {
  return (
    <Card className="offer" as={linkTo ? Link : undefined} to={linkTo} centered {...props}>
      <Card.Content>
        { vendor && <Label ribbon="right" content={hidden ? 'Ανενεργή' : 'Ενεργή'} color={hidden ? 'red' : 'green'} /> }
        <Card.Header>{ title }</Card.Header>
        <Card.Meta>{ business?.name }</Card.Meta>
      </Card.Content>
      <Card.Content description={description} />
      <Card.Content extra>
        <Card.Description>Χρειάζονται <strong>{required_points}</strong> πόντοι.</Card.Description>
        { vendor && days_until_expiration && <Card.Description>Τα κουπόνια έχουν διάρκεια ισχύος <strong>{days_until_expiration}</strong> ημέρ{days_until_expiration === 1 ? 'α' : 'ες'}.</Card.Description> }
      </Card.Content>
    </Card>
  );
}

export function Offer(props) {
  const history = useHistory();
  const { offerId } = useParams();
  const queryClient = useQueryClient();

  const { data: user, refetch: refetchProfile } = useProfile();
  const { participant, business } = user || {};
  const { id: vendorId } = business || {};

  const { data: offer, refetch } = useOffer(offerId);

  const couponMutation = useMutation(
    () => axios.post('/api/coupons/', { offer: offerId }).then(({data}) => data),
    { 
      onSuccess: ({ id }) => {
        refetchProfile();
        history.push(`/coupons/${id}`);
      }
    }
  );

  const toggleMutation = useMutation(
    () => axios.post(`/api/offers/${offerId}/toggle/`).then(({data}) => data),
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries(['offers', { vendorId }]);
      }
    }
  );
  
  const { hidden, pending_coupons_count, required_points } = offer || {};

  return (
    <Modal size="tiny" open {...props}>
      <Modal.Header>Λεπτομέρειες προσφοράς</Modal.Header>
      <Modal.Content>
        <OfferCard fluid offer={offer} vendor={!!vendorId} />

        { vendorId && pending_coupons_count > 0 && 
          <Message icon warning>
            <Icon name="exclamation triangle" />
            <Message.Content>
              <p>
                Υπάρχ{pending_coupons_count === 1 ? 'ει ' : 'ουν '}
                <strong>{pending_coupons_count}</strong> κουπόνι{pending_coupons_count === 1 ? ' ' : 'α '}
                της προσφοράς αυτής που εκκρεμ{pending_coupons_count === 1 ? 'εί' : 'ούν'}.
              </p>
              { hidden === false && <p>
                Μπορείτε να αποκρύψετε την προσφορά αυτή, αλλά {pending_coupons_count === 1 ? 'το κουπόνι αυτό ' : 'τα κουπόνια αυτά '}
                θα εξακολουθ{pending_coupons_count === 1 ? 'εί ' : 'ούν '}
                να είναι έγκυρ{pending_coupons_count === 1 ? 'ο' : 'α'},
                μέχρις ότου λήξ{pending_coupons_count === 1 ? 'ει' : 'ουν'},
                ακυρωθ{pending_coupons_count === 1 ? 'εί' : 'ούν'}, ή εξαργυρωθ{pending_coupons_count === 1 ? 'εί' : 'ούν'}.
              </p> }
              <p>
                Αν επιθυμείτε { !hidden && <>την απενεργοποίηση της προσφοράς και </> } την ακύρωση { hidden && <>και</> }
                {pending_coupons_count === 1 ? ' του κουπονιού αυτού' : 'των κουπονιών αυτών'}, παρακαλούμε επικοινωνήστε μαζί μας.
              </p>
            </Message.Content>
          </Message>
        }

      </Modal.Content>
      <Modal.Actions>
        { vendorId ? 
            <Button positive={hidden} negative={!hidden}
              content={ hidden ? 'Ενεργοποίηση' : 'Απενεργοποίηση' }
              onClick={() => toggleMutation.mutate()}
            />
            :
            user?.is_supervisor === false && 
              <PopupOnMutationError component={
                <Button
                  primary={!!user}
                  disabled={
                    couponMutation.isLoading
                    || !participant
                    || participant.points_balance < required_points
                  }
                  onClick={() => couponMutation.mutate()}
                  content={ user?.participant ? 'Έκδοση κουπονιού' : 'Συνδεθείτε για έκδοση κουπονιού' }
                  loading={couponMutation.isLoading}
                />
              } mutation={couponMutation} content="Δεν ήταν δυνατή η έκδοση του κουπονιού" />
        }

        <Button onClick={props.onClose}>Επιστροφή</Button>
      </Modal.Actions>
    </Modal>
  );
}

function Offers({ homepage }) {
  const match = useRouteMatch();
  const history = useHistory();

  const baseUrl = match.url + (homepage ? 'offers' : '');
  const basePath = match.path + (homepage ? 'offers' : '');

  const { data: user } = useProfile();
  const { business } = user || {};
  const { id: vendorId } = business || {};

  // show only enabled offers if not a vendor
  const offers = useOffers({ vendorId, enabledOnly: !vendorId });

  const handleOfferClose = () => history.push(baseUrl);

  const pageTitle = 'Προσφορές';
  return (<>
    <Helmet title={pageTitle} />
    <Container text textAlign="center">
      <Header as="h1" className="colored atmgreen" content={pageTitle} />
      <p>
        Καλώς ήρθες στην πλατφόρμα επιβράβευσης με εξαργύρωση πόντων της ΔΙΑΔΥΜΑ!
      </p>
      
      <p>
        Εδώ εξαργυρώνεις τους πόντους σου απλά και γρήγορα,
        σε μοναδικές προσφορές των καταστημάτων των συνεργαζόμενων επιχειρήσεων.
      </p>
    </Container>

    <Divider hidden />

    <Container>
      <Divider className="orange" />
      <Divider hidden />

      <PointsBalance />

      { offers.isLoading && <Loader active /> }
      { offers.isError && <Message error content="Κάτι πήγε στραβά!" /> }

      <Divider hidden />
      { offers.data && 
        <Card.Group>
          { offers.data.map((offer) =>
              <OfferCard key={offer.id} id={offer.id} linkTo={`${baseUrl}/${offer.id}`} offer={offer} vendor={!!vendorId} />
            )
          }
        </Card.Group>
      }
      { offers.data?.length === 0 && 
          <Container text>
            <Divider hidden />
            { vendorId ? 
                <Message>
                  <p>Δεν υπάρχει καμία καταχωρημένη προσφορά για την επιχείρησή σας.</p>
                  <p>Για εισαγωγή νέων προσφορών ή την τροποποίηση υφιστάμενων επικοινωνήστε με την ΔΙΑΔΥΜΑ ΑΕ</p>
                  <ul className="dashlist">
                    <li>Τηλ: 24610 45531,3</li>
                    <li>Website: <a href="https://diadyma.gr/">https://diadyma.gr/</a></li>
                  </ul>
                </Message>
                : 
                <Message>
                  <p>Αυτή τη στιγμή δεν υπάρχει καμία ενεργή προσφορά για την εξαργύρωση πόντων επιβράβευσης.</p>
                  <p>Η ΔΙΑΔΥΜΑ ΑΕ ετοιμάζει τις επόμενες προσφορές που θα είναι σύντομα κοντά σας.</p>
                </Message>
            }
          </Container>
      }
      <Route path={`${basePath}/:offerId(\\d+)`}>
        <Offer dimmer="inverted" onClose={handleOfferClose} />
      </Route>

      <Divider section hidden />
    </Container>
  </>);
}
  
export default Offers;
