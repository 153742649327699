import { Segment, Header, Statistic } from 'semantic-ui-react';
import { useQuery } from 'react-query';

import useProfile from '../queries/useProfile';

import axios from 'axios';


export default function PointsRedeemed({ plain, label='Πόντους', ...props }) {
  const profile = useProfile();

  // for vendors returns the redeemed coupons for offers by the vendor
  // for users  returns the redeemed coupons for the user
  // for admins returns everything
  let { data: totalPoints } = useQuery('redemptionsTotal', () =>
    axios.get('/api/coupons/total/', { params: { status: 'redeemed' } }).then(({data}) => data),
    { enabled: profile.data && !profile.data.participant }
  );

  if (!profile.data) return null;

  let statisticText;
  if (profile.data.participant) {
    totalPoints = profile.data.participant.points_redeemed;
    statisticText = 'Έχεις χρησιμοποιήσει κουπόνια που αξιοποίησαν';
  } else if (profile.data.business) {
    // totalPoints = profile.data.points_redeemed;
    statisticText = 'Έχετε αποδεχτεί κουπόνια που αθροίζουν';
  } else {
    // staff
    statisticText = 'Έχουν εξαργυρωθεί κουπόνια που αθροίζουν συνολικά';
  };

  const statistic = (
    <Statistic {...props}>
      <Statistic.Value>{ totalPoints }</Statistic.Value>
      <Statistic.Label>{ label }</Statistic.Label>
    </Statistic>
  );

  return plain ? statistic : (
    <>
      <Header as="h2" textAlign="center">{ statisticText }</Header>
      <Segment basic textAlign="center">
        { statistic }
      </Segment>
    </>
  );
}
