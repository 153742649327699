import { Link, NavLink, useLocation, matchPath } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { ResponsiveContainer } from '@zerowaste/components';

import useProfile from '../queries/useProfile';

import qs from 'qs';

function RewardsMenuItems({ tight }) {
  const location = useLocation();

  const isOffers = !!(
    matchPath(location.pathname, { path: '/', exact: true })
    || matchPath(location.pathname, '/offers')
  );

  return (
    <>
      <Menu.Item as={Link} to="/offers" active={isOffers}>Προσφορες</Menu.Item>
      <Menu.Item as={NavLink} to="/points">Διαδικασια{ tight ? <br/> : ' '}επιβραβευσης</Menu.Item>
      <Menu.Item as={NavLink} to="/questions">Συχνες ερωτησεις</Menu.Item>
      <Menu.Item as={NavLink} to="/news">Νεα</Menu.Item>
    </>
  );
}

export default function RewardsContainer({ children, onLogin, onRegister, onLogout, showAuth=true }) {
  const profile = useProfile();
  const user = profile.data;

  const location = useLocation();
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const userMenuItems = user?.participant &&
    <>
      <Menu.Item as={NavLink} to="/profile">Λογαριασμός</Menu.Item>
      <Menu.Item as={NavLink} to="/coupons">Τα κουπονια μου</Menu.Item>
      <Menu.Item as={NavLink} to="/history">Ιστορικο</Menu.Item>
    </>
  ;

  const vendorMenuItems = user?.business &&
    <>
      <Menu.Item as={NavLink} to="/vendor" exact>Εξαργυρωση</Menu.Item>
      <Menu.Item as={NavLink} to="/vendor/history">Ιστορικο</Menu.Item>
      {/* <Menu.Item as={NavLink} to="/vendor/offers">Προσφορες</Menu.Item> */}
      <Menu.Item as={NavLink} to="/vendor/profile">Προφιλ</Menu.Item>
    </>
  ;

  return (
    <ResponsiveContainer className="header-menu"
      headerTitle="Πλατφόρμα εξαργύρωσης πόντων"
      userPaths={['/profile', '/coupons', '/history', '/vendor']}
      contentPaths={['/', '/offers', '/points', '/questions', '/news']}
      user={user}
      showLoginRegister={profile.isFetched && !code}
      onLogin={onLogin}
      onLogout={onLogout}
      onRegister={onRegister}
      showAuth={showAuth}
      notLoggedIn={profile.isFetched && !profile.data}
      displaySettings={!!user?.participant}
      menuItems={<RewardsMenuItems />}
      userMenu={ showAuth && (userMenuItems || vendorMenuItems)}
      adminMenu={
        showAuth && user?.is_supervisor &&
          <>
            <Menu.Item as={NavLink} to="/admin/dashboard" exact>Επισκοπηση</Menu.Item>
            <Menu.Item as={NavLink} to="/admin/vendors">Καταστηματα</Menu.Item>
            <Menu.Item as={NavLink} to="/admin/history">Ιστορικο</Menu.Item>
            <Menu.Item as={NavLink} to="/admin/news">Νεα</Menu.Item>
          </>
      }
    >
      {children}
    </ResponsiveContainer>
  );

}
