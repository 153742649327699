import { useQuery } from 'react-query';

import axios from 'axios';


export const useCoupons = (params, { onPageInvalid, onError, ...options}={}) => useQuery(['coupons', params],
  async () => {
    let { status, code } = params;

    // only filter by status when no code is searched
    if (code?.length || status === 'all') {
      status = undefined;
    }
    // clear code from params if empty
    if (!code) code = undefined;

    // update params
    params = { ...params, status, code };

    const { data } = await axios.get('/api/coupons/', { params } );
    return data;
  },
  {
    keepPreviousData: true,
    retry: (failureCount, error) => {
      // don't retry on invalid page errors
      if (error.response.data?.detail === 'Invalid page.') return false;
      return failureCount < 2; // default behaviour
    },
    onError: onPageInvalid && ((error) => {
      if (error.response.data?.detail === 'Invalid page.') {
        onPageInvalid();
      } else if (onError) {
        onError(error);
      } else {
        throw error;
      }
    }),
    ...options,
  }
);

export const useCoupon = (id) => useQuery(['coupon', id],
  () => axios.get(`/api/coupons/${id}/`).then(({data}) => data)
);

export const useCouponsExport = (params, options) =>
  useQuery(
    ['couponsExport', params],
    () =>
      axios
        .get('/api/coupons/export/', {
          // remove status if status is all
          params: {
            ...params,
            status: params.status === 'all' ? undefined : params.status,
          },
          responseType: 'blob',
        })
        .then(({ data }) => data),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
