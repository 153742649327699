import { useEffect } from 'react';
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { Announcements, useToken } from '@zerowaste/components';

import Offers from './Offers';
import Coupons from './Coupons';
import RedemptionsHistory from './RedemptionsHistory';
import FaqPage from './FaqPage';
import RewardsProcess from './RewardsProcess';
import { PasswordResetModal, PasswordChangeForm } from './PasswordReset';
import ActivateAccount from './ActivateAccount';
import Terms from './Terms';
import ParticipantProfile from './ParticipantProfile';

import useProfile from '../queries/useProfile';

import axios from 'axios';
import qs from 'qs';


function ExecuteEffect({ onMount }) {
  useEffect(onMount);
  return null;
}


function RewardsHome({ onLogin, onRegister }) {
  const location = useLocation();
  const history = useHistory();

  const { setToken } = useToken();

  const { code, state: returnTo } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(
    () => {
      if (!code) return;

      axios.post('/api/zerowaste/auth/', { code }).then(
        ({ data }) => {
          setToken(data.token);
          history.replace(returnTo);
        },
        (err) => {
          // TODO show something to the user ???
          console.warn(err);
          // just remove the code part, and stay at home page
          history.replace({ search: '' });
        }
      );

    },
    [code, returnTo, setToken, history]
  );

  const profile = useProfile();
  const unknownOrParticipant = !profile.data || profile.data.participant;

  const redirectNonParticipant = !profile.data
    ? '/'
    : profile.data.business
    ? '/vendor'
    : '/admin';

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Offers homepage />
        </Route>

        <Route path="/offers">
          <Offers />
        </Route>

        <Route path="/points">
          <RewardsProcess onRegister={onRegister('/profile')} />
        </Route>

        <Route path="/questions">
          <FaqPage />
        </Route>

        <Route path="/news">
          <Announcements />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route exact path="/password/reset" component={PasswordResetModal} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          component={PasswordChangeForm}
        />
        <Route
          path="/account/activate/:uid/:token"
          component={ActivateAccount}
        />

        {profile.isFetched && !code && unknownOrParticipant && (
          <>
            <Route key="profile" path="/profile">
              {profile.data?.participant ? (
                <ParticipantProfile refetchOnMount={!code} />
              ) : (
                <ExecuteEffect onMount={onLogin('/profile')} />
              )}
            </Route>

            <Route key="coupons" path="/coupons">
              {profile.data?.participant ? (
                <Coupons />
              ) : (
                <ExecuteEffect onMount={onLogin('/coupons')} />
              )}
            </Route>

            <Route key="history" path="/history/:pageKey(page)?/:page(\d+)?">
              {profile.data?.participant ? (
                <RedemptionsHistory />
              ) : (
                <ExecuteEffect onMount={onLogin('/history')} />
              )}
            </Route>
          </>
        )}

        {/* if we are still loading the user, let's show a loader instead of redirecting */}
        <Route path="*">
          {!profile.isFetched || code ? (
            <Loader inline="centered" size="massive" active />
          ) : (
            <Redirect to={redirectNonParticipant} />
          )}
        </Route>
      </Switch>
    </>
  );
}


export default RewardsHome;
