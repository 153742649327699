import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { AnnouncementsAdmin } from '@zerowaste/components';

import { RedemptionsHistoryAdmin } from './RedemptionsHistory';
import VendorsAdmin from './VendorsAdmin';
import RewardsDashboard from './RewardsDashboard';

import useProfile from '../queries/useProfile';

import qs from 'qs';


export default function RewardsAdmin({ onLogin }) {
  const location = useLocation();
  const profile = useProfile();

  const { cancelTo, ...params } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (profile.isFetched && !profile.data) {
      // if we are logged out and want to go to admin, let's redirect them to login page
      onLogin?.(location.pathname + `?${qs.stringify(params)}`, '/')();
    }
  }, [profile, location, params, onLogin])

  // wait for it...
  if (!profile.isFetched) {
    return <Loader inline="centered" size="massive" active />;
  }

  // if we are business owner and want to go to admin, let's send them to the business admin pages
  if (profile.data?.business) {
    return <Redirect to="/vendor" replace />;
  }

  if (profile.data?.participant) {
    // we are a participant, just take them to the root
    return <Redirect to="/" replace />;
  }

  if (!profile.data) {
    if (onLogin) {
      // if logged out, useEffect will do the rest
      return null;
    } else {
      // useEffect won't do anything, fallback to local login screen
      return <Redirect to={{
        pathname: '/login',
        search: `?${qs.stringify({
          returnTo: location.pathname + `?${qs.stringify(params)}`,
          cancelTo,
        })}`
      }} />;
    }
  }

  return (
    <Switch>
      <Route path="/admin" exact>
        <Redirect to="/admin/dashboard" />
      </Route>

      <Route path="/admin/dashboard">
        <RewardsDashboard />
      </Route>

      <Route path="/admin/vendors">
        <VendorsAdmin />
      </Route>

      <Route path="/admin/history">
        <RedemptionsHistoryAdmin />
      </Route>

      <Route path="/admin/news">
        <AnnouncementsAdmin />
      </Route>

      <Route path="*">
        <Redirect to="/admin" />
      </Route>

    </Switch>
  );
}
