import { Fragment, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, Route, Redirect, useLocation, useRouteMatch, useHistory, useParams, generatePath } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Message, Segment, Table, Header, Dropdown, Icon, Form, Grid, Divider, Button, Sticky, Ref } from 'semantic-ui-react';

import { OrientedPagination, ConfirmPopup, useDebounce } from '@zerowaste/components';

import { VendorsList } from './VendorsAdmin';
import { ParticipantsList } from './ParticipantsAdmin';
import PointsRedeemed from './PointsRedeemed';

import { useCoupons, useCouponsExport } from '../queries/coupons';

import qs from 'qs';
import axios from 'axios';
import omit from 'lodash/omit';

function CodeSearchForm({ onCodeSearch, mobile, loading }) {
  const [couponCode, setCouponCode] = useState('');
  const handleClear = () => {
    setCouponCode('');
    onCodeSearch('');
  }

  return (
    <Form onSubmit={() => onCodeSearch(couponCode)}>
      <Form.Group grouped={mobile} inline={!mobile}>
        <Form.Input icon={ couponCode ? <Icon name="delete" link onClick={handleClear} /> : "search"}
          value={couponCode} onChange={(e, {value}) => setCouponCode(value)}
          disabled={loading}
        />
        <Form.Button fluid={mobile} primary content="Αναζήτηση κουπονιού"
          disabled={loading} loading={loading}
        />
      </Form.Group>
    </Form>
  );
}

export function RedemptionsHistoryAdmin() {
  const match = useRouteMatch();

  const { search } = useLocation();
  const filter = qs.parse(search, { ignoreQueryPrefix: true });

  const pageTitle = 'Ιστορικό κουπονιών';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />

      <Divider hidden />
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <VendorsList listHeight="20vh" parentPageTitle={pageTitle} parentMatch={match} filter={filter} />
            <Divider />
            <ParticipantsList listHeight="20vh" baseUrl={match.url} filter={filter} />
          </Grid.Column>

          <Grid.Column width={12}>
            <Route path={`${match.path}/:pageKey(page)?/:page(\\d+)?`}>
              <RedemptionsHistory admin filter={filter} />
            </Route>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

// renders a cancel component for each coupon
function CancelCouponButton({ position, button, cancelCoupon, coupon: { id, code, status }}) {
  const cancellable = (status === 'valid' || status === 'inactive');

  const isLoading = cancelCoupon.variables === id && cancelCoupon.isLoading;
  const isError = cancelCoupon.variables === id && cancelCoupon.isError;

  const trigger = button ? 
    <Button icon="cancel" negative compact
      content="Ακύρωση κουπονιού"
      title={ cancellable ? undefined : 'Δεν επιτρέπεται η ακύρωση'}
      disabled={!cancellable}
      loading={isLoading}
    />
    : <Icon name="times circle" color="red" size="large"
      title={cancellable ? 'Ακύρωση κουπονιού' : 'Δεν επιτρέπεται η ακύρωση'}
      disabled={!cancellable}
      link={cancellable}
      loading={isLoading}
    />
  ;

  return (
    <ConfirmPopup position={position} trigger={trigger}
      onConfirm={() => cancelCoupon.mutate(id)}
      error={isError && 'Δεν ήταν δυνατή η ακύρωση του κουπονιού.' }
      onDismissError={() => cancelCoupon.reset()}
      positive="Ακύρωση" negative="Επιστροφή"
    >
      Μπορείτε να ακυρώσετε το κουπόνι <strong>{code}</strong> άμεσα,
      και ο χρήστης θα πιστωθεί τους αντίστοιχους πόντους.
    </ConfirmPopup>
  );
}

function RedemptionsTableComputer({ baseUrl, admin, activeParticipant, vendor, coupons, couponsCount, emptyResults, cancelCoupon, ...props }) {
  let colSpan = 6;
  if (admin) {
    if (couponsCount > 0) colSpan += 1;
    if (!activeParticipant) colSpan += 1;
  }
  if (!vendor) colSpan += 1;

  return (
    <Table celled structured {...props}>
      <Table.Header>
        <Table.Row textAlign="center">
          {admin && couponsCount > 0 && <Table.HeaderCell collapsing>
            {/* to keep the column width static */}
            <Icon name="times circle" size="large" style={{ visibility: 'hidden' }} />
          </Table.HeaderCell> }
          <Table.HeaderCell>Κατάσταση</Table.HeaderCell>
          { admin && !activeParticipant && <Table.HeaderCell>Χρήστης</Table.HeaderCell> }
          <Table.HeaderCell>Κωδικός</Table.HeaderCell>
          <Table.HeaderCell colSpan={2}>Ημερομηνίες</Table.HeaderCell>

          { !vendor && <Table.HeaderCell>Κατάστημα</Table.HeaderCell> }
          
          <Table.HeaderCell>Προσφορά</Table.HeaderCell>
          <Table.HeaderCell>Πόντοι</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {coupons?.map(
          ({id, nonActivated, participant, code, created_at, status, is_inactive, activated_at, cancelled_at, redeemed_at, is_expired, expired_at, offer: { title, business: { name } }, points }) => {

            const flags = {
              negative: !!(cancelled_at || is_expired),
              positive: !(redeemed_at || cancelled_at || is_expired),
              warning: !!redeemed_at,
            };

            const statusText = is_inactive ?
              'Προς επεξεργασία' :
              ( cancelled_at ?
                'Ακυρωμένο' :
                ( redeemed_at ?
                  'Εξαργυρωμένο' :
                  ( is_expired ? 'Ληγμένο' : 'Ενεργό' )
                )
              )
            ;

            const cancellable = (status === 'valid' || status === 'inactive');

            const rowSpan = 2;
            return (
              <Fragment key={id}>
                <Table.Row verticalAlign="top" {...flags}>

                  { admin &&
                    <Table.Cell rowSpan={rowSpan} collapsing>
                      { cancellable &&
                        <CancelCouponButton position="right center" cancelCoupon={cancelCoupon} coupon={{ id, code, status }} />
                      }
                    </Table.Cell>
                  }

                  <Table.Cell rowSpan={rowSpan}><strong>{statusText}</strong></Table.Cell>
                  { admin && !activeParticipant && <Table.Cell rowSpan={rowSpan}>{participant?.full_name || 'Άγνωστο όνομα'}</Table.Cell> }
                  <Table.Cell rowSpan={rowSpan}>{code}</Table.Cell>
                  {status === 'inactive' || status === 'valid' || (status === 'cancelled' && nonActivated) ?
                    <>
                      <Table.Cell>Έκδοσης</Table.Cell>
                      <Table.Cell>{(created_at?.toLocaleString('el') || '-')}</Table.Cell>
                    </> : !(status === 'cancelled' && nonActivated) ?
                      <>
                        <Table.Cell>Ενεργοποίησης</Table.Cell>
                        <Table.Cell>{(activated_at?.toLocaleString('el') || '-')}</Table.Cell>
                      </> : null
                  }

                  { !vendor && <Table.Cell rowSpan={rowSpan}>{name}</Table.Cell> }

                  <Table.Cell rowSpan={rowSpan}>{title}</Table.Cell>
                  <Table.Cell rowSpan={rowSpan}>{points}</Table.Cell>
                </Table.Row>
                { (redeemed_at || cancelled_at) &&
                  <Table.Row {...flags}>
                    <Table.Cell>{ redeemed_at?.toLocaleString('el') ? 'Εξαργύρωσης' : 'Ακύρωσης' }</Table.Cell>
                    <Table.Cell>{(redeemed_at?.toLocaleString('el') || cancelled_at?.toLocaleString('el'))}</Table.Cell>
                  </Table.Row>
                }
                { is_expired &&
                  <Table.Row {...flags}>
                    <Table.Cell>Λήξης</Table.Cell>
                    <Table.Cell>{expired_at?.toLocaleString('el')}</Table.Cell>
                  </Table.Row>
                }
                {status === 'inactive' || status === 'valid' ?
                  <Table.Row {...flags}>
                    <Table.Cell>Ενεργοποίησης</Table.Cell>
                    <Table.Cell>{activated_at?.toLocaleString('el')}</Table.Cell>
                  </Table.Row> : null}
              </Fragment>
            );
          })
        }
        {couponsCount === 0 &&
          <Table.Row textAlign="center">
            <Table.Cell colSpan={colSpan}>
              { emptyResults }
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>
  );
}

function RedemptionsTableMobile({ baseUrl, admin, activeParticipant, vendor, coupons, couponsCount, emptyResults, cancelCoupon, ...props }) {
  return (<>
    <Table unstackable definition fixed {...props}>
      <Table.Body>
        { coupons?.map(
          ({ id, nonActivated, participant, code, created_at, status, is_inactive, activated_at, cancelled_at, redeemed_at, is_expired, expired_at, offer: { title, business: { name } }, points }, index) => {

            const flags = {
              negative: !!(cancelled_at || is_expired),
              positive: !(redeemed_at || cancelled_at || is_expired),
              warning: !!redeemed_at,
            };

            const statusText = is_inactive ?
              'Προς επεξεργασία' :
              ( cancelled_at ?
                'Ακυρωμένο' :
                ( redeemed_at ?
                  'Εξαργυρωμένο' :
                  ( is_expired ? 'Ληγμένο' : 'Ενεργό' )
                )
              )
            ;

            const cancellable = (status === 'valid' || status === 'inactive');

            return (
              <Fragment key={id}>
                { index > 0 && 
                  <Table.Row><Table.Cell style={{ backgroundColor: 'white' }} colSpan={2} /></Table.Row>
                }

                { admin && !activeParticipant && 
                  <Table.Row {...flags}>
                    <Table.Cell>Χρήστης</Table.Cell>
                    <Table.Cell>{participant?.full_name || 'Άγνωστο όνομα'}</Table.Cell> 
                  </Table.Row>
                }

                <Table.Row {...flags}>
                  <Table.Cell>Κωδικός</Table.Cell>
                  <Table.Cell>{code}</Table.Cell>
                </Table.Row>

                { !vendor &&
                  <Table.Row {...flags}>
                    <Table.Cell>Κατάστημα</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                  </Table.Row>
                }

                <Table.Row {...flags}>
                  <Table.Cell>Προσφορά</Table.Cell>
                  <Table.Cell>{title}</Table.Cell>
                </Table.Row>

                <Table.Row {...flags}>
                  <Table.Cell>Πόντοι</Table.Cell>
                  <Table.Cell>{points}</Table.Cell>
                </Table.Row>

                <Table.Row {...flags}>
                  <Table.Cell>
                    Κατάσταση
                  </Table.Cell>
                  <Table.Cell>
                    { statusText }
                  </Table.Cell>
                </Table.Row>

                {
                  (status === 'inactive' || status === 'valid' || (status === 'cancelled' && nonActivated)) &&
                  <Table.Row {...flags}>
                    <Table.Cell>Ημερομηνία έκδοσης</Table.Cell>
                    <Table.Cell>{(created_at?.toLocaleString('el') || '-')}</Table.Cell>
                  </Table.Row>
                }

                {status === 'cancelled' && nonActivated ? null : <Table.Row {...flags}>
                  <Table.Cell>Ημερομηνία ενεργοποίησης</Table.Cell>
                  <Table.Cell>{activated_at?.toLocaleString('el')}</Table.Cell>
                </Table.Row>}

                {(redeemed_at || cancelled_at) &&
                  <Table.Row {...flags}>
                    <Table.Cell>Ημερομηνία {redeemed_at ? 'εξαργύρωσης' : 'ακύρωσης'}</Table.Cell>
                    <Table.Cell>{(redeemed_at?.toLocaleString('el') || cancelled_at?.toLocaleString('el'))}</Table.Cell>
                  </Table.Row>
                }
                { is_expired &&
                  <Table.Row {...flags}>
                    <Table.Cell>Ημερομηνία λήξης</Table.Cell>
                    <Table.Cell>{expired_at?.toLocaleString('el')}</Table.Cell>
                  </Table.Row>
                }

                { admin && cancellable &&
                  <Table.Row>
                    <Table.Cell textAlign="center" style={{ backgroundColor: 'white' }} colSpan={2}>
                      <CancelCouponButton button position="bottom left" cancelCoupon={cancelCoupon} coupon={{ id, code, status }} />
                    </Table.Cell>
                  </Table.Row>
                }

              </Fragment>
            );
          })
        }
        { couponsCount === 0 &&
          <Table.Row textAlign="center">
            <Table.Cell colSpan={2}>
              { emptyResults }
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>
  </>);
}

function RedemptionsTable({ emptyResults, coupons, ...props }) {
  const contextRef = useRef();

  const history = useHistory();
  const queryClient = useQueryClient();

  const { page } = useParams();
  const { search } = useLocation();

  if (!emptyResults) {
    emptyResults = (<>
      Δεν εντοπίστηκαν κουπόνια που ικανοποιούν τα φίλτρα που έχετε επιλέξει.
      <Divider hidden fitted />
      <Link to={props.baseUrl}>Επαναφορά φίλτρων</Link>
    </>);
  }

  const cancelCoupon = useMutation(
    (couponId) => axios.post(`/api/coupons/${couponId}/cancel/`),
    { onSuccess: () => queryClient.invalidateQueries('coupons') }
  )

  const paginationProps = {
    activePage: page,
    totalPages: coupons.data?.total_pages,
    disabled: coupons.data?.total_pages === 1,
    onPageChange: (e, { activePage }) => history.push({ pathname: `${activePage}`, search }),
  };

  const couponsData = coupons.data?.results.map(({ created_at, activated_at, cancelled_at, redeemed_at, expired_at, ...rest }) => {
    created_at = created_at && new Date(created_at);
    activated_at = activated_at && new Date(activated_at);
    cancelled_at = cancelled_at && new Date(cancelled_at);
    redeemed_at = redeemed_at && new Date(redeemed_at);
    expired_at = expired_at && new Date(expired_at);

    const nonActivated = cancelled_at && (cancelled_at < activated_at);
    return {
      created_at,
      activated_at,
      cancelled_at,
      redeemed_at,
      expired_at,
      nonActivated,
      ...rest
    }
  })

  const couponsCount = coupons.data?.count;
  return (
    <Grid>
      <Grid.Row only="mobile">
        <Ref innerRef={contextRef}>
          <div style={{ textAlign: 'center' }}>
            <Sticky context={contextRef}>
              <OrientedPagination {...paginationProps}
                // override some props to be mobile friendly
                boundaryRange={1} siblingRange={0}
                size="large" pointing={false} secondary={false}
              />
            </Sticky>
            <RedemptionsTableMobile emptyResults={emptyResults} cancelCoupon={cancelCoupon} coupons={couponsData} couponsCount={couponsCount} {...props} />
          </div>
        </Ref>
      </Grid.Row>
      <Grid.Row only="tablet computer">
        <OrientedPagination {...paginationProps} />
        <RedemptionsTableComputer emptyResults={emptyResults} cancelCoupon={cancelCoupon} coupons={couponsData} couponsCount={couponsCount} {...props} />
      </Grid.Row>
    </Grid>
  );
}

export default function RedemptionsHistory({ admin, vendor, filter }) {
  const history = useHistory();
  const match = useRouteMatch();

  const { search } = useLocation();
  if (!filter) filter = qs.parse(search, { ignoreQueryPrefix: true });

  // try and pick the vendor id from the path instead of the search string
  // this will keep the vendor out of the search string whenever filter changes,
  // but will enable filtering by vendor;
  const params = useParams();
  const { vendorId, page } = params;
  const isVendorAdminPage = !!vendorId;

  let { vendor: activeVendor } = filter;
  if (!activeVendor) activeVendor = vendorId;

  const {
    participant: activeParticipant,
    status: statusFilter='all',
    couponCode: codeFilter='',
  } = filter;

  const handleChangeStatus = (e, { value }) => history.push({
    search: '?' + qs.stringify({
      ...filter,
      status: value !== 'all' ? value : undefined, // clear status from URL if 'all',
    })
  });

  const handleCodeSearch = (value) => history.push({
    search: '?' + qs.stringify({
      ...filter,
      couponCode: value || undefined,
    })
  });

  // get the base path without the paging params
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  // this holds coupons publication history (everything except redemptions)
  const couponsParams = { status: statusFilter, code: codeFilter, vendor: activeVendor, participant: activeParticipant, page };
  if (isVendorAdminPage) {
    couponsParams.status = 'pending';
  }

  const coupons = useCoupons(couponsParams,
    { onPageInvalid: () => history.replace({ pathname: baseUrl, search }) }
  );
  const debouncedFetching = useDebounce(coupons.isFetching, 500);

  // Excel export
  const exportExcel = useCouponsExport({
    status: isVendorAdminPage ? 'pending' : statusFilter,
    vendor: activeVendor,
    participant: activeParticipant,
  });
  const handleExportData = () => {
    exportExcel.refetch().then((response) => {
      const file = new File([response.data], 'Ιστορικό κουπονιών.xlsx', {
        type: response.data.type,
      });
      const href = URL.createObjectURL(file);
      window.location = href;
      URL.revokeObjectURL(href);
    });
  };

  if (!coupons.isPreviousData && coupons.data?.total_pages > 1 && !page) {
    return <Redirect to={{ pathname: `${baseUrl}/page/1`, search }} />
  }

  const statusOptions = [
    { text: 'Όλα', value: 'all' },
    { text: 'Ενεργά', value: 'valid' },
    { text: 'Εξαργυρωμένα', value: 'redeemed' },
    { text: 'Ληγμένα', value: 'expired' },
    { text: 'Ακυρωμένα', value: 'cancelled' },
  ];

  if (!vendor) {
    statusOptions.push(
      { text: 'Προς επεξεργασία', value: 'inactive' },
    );
  }

  const emptyResults = isVendorAdminPage && <>
      Δεν υπάρχουν εκκρεμή κουπόνια για το κατάστημα.
  </>;

  const filterComponent = (
    <>
      <label>Φίλτρο κατάστασης: </label>
      <Dropdown inline options={statusOptions} direction="left" labeled
        value={statusFilter} onChange={handleChangeStatus}
        disabled={!!codeFilter}
        style={{ position: 'relative', zIndex: 1000 }} // to keep it on top of loading panel below
      />
    </>
  )

  const pageTitle = 'Ιστορικό κουπονιών';
  return (
    <Container>
      { !admin && <>
          <Helmet title={pageTitle} />
          <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
          <Divider className="orange" />

          <PointsRedeemed />
        </>
      }

      { !isVendorAdminPage &&
        <Grid verticalAlign={ admin ? undefined : 'middle' }>
          <Grid.Column width={10} only="computer tablet">
            <CodeSearchForm onCodeSearch={handleCodeSearch} loading={!!codeFilter && debouncedFetching} />
          </Grid.Column>
          <Grid.Column width={16} only="mobile">
            <CodeSearchForm onCodeSearch={handleCodeSearch} loading={!!codeFilter && debouncedFetching} mobile />
          </Grid.Column>
            <Grid.Column computer={6} tablet={6} mobile={16} textAlign="right">
            { admin ?
              <Button content="Εξαγωγή σε Excel" icon="download"
                onClick={handleExportData}
                loading={exportExcel.isFetching}
                disabled={exportExcel.isFetching}
              />
              : filterComponent
            }
            </Grid.Column>
        </Grid>
      }

      { admin && !isVendorAdminPage && <Container textAlign="right">{ filterComponent }</Container> }

      <Segment basic placeholder={coupons.isLoading} loading={debouncedFetching} style={{ marginTop: '-1em' }}>
        { coupons.data && !coupons.isError &&
          <RedemptionsTable baseUrl={match.url}
            admin={admin} vendor={vendor || activeVendor} activeParticipant={activeParticipant}
            coupons={coupons}
            emptyResults={emptyResults}
          />
        }

        { coupons.isError &&
          <Message size="large" compact error>
            <Message.Content>
              <Message.Header content="Κάτι πήγε στραβά!" />
              <p>Οι πληροφορίες για τα κουπονια δεν είναι διαθέσιμες.</p>
              <p><Button color="brown" compact icon={<Icon name="refresh" loading={debouncedFetching} />} content="Ανανέωση" /></p>
            </Message.Content>
          </Message>
        }
      </Segment>

      { !admin && !vendor && <Divider hidden /> }
    </Container>
  );
}
