import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Divider, Header, Loader } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import RedeemForm from './RedeemForm';
import RedemptionsHistory from './RedemptionsHistory';
// eslint-disable-next-line no-unused-vars
import { Vendor, VendorOffers } from './VendorsAdmin';

import qs from 'qs';


function RewardsVendorHome({ onLogout }) {
  const location = useLocation();
  const profile = useProfile();

  // if we are admin, let's send them to the full admin pages
  if (profile.isFetched && profile.data.is_supervisor) {
    return <Redirect to="/admin" />;
  }

  const { cancelTo, ...params } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // if we are some other user and want to go to vendor, let's send them to login page
  if (profile.isFetched && !profile.data?.business) {
    return <Redirect to={{
      pathname: '/login',
      search: `?${qs.stringify({
        returnTo: location.pathname + `?${qs.stringify(params)}`,
        cancelTo,
      })}`
    }} />;
  }

  // we're in business then
  const business = profile.data?.business;

  const filter = qs.parse(location.search, { ignoreQueryPrefix: true });

  const basePageTitle = business?.name && `${business?.name} | Πλατφόρμα εξαργύρωσης πόντων – ΔΙΑΔΥΜΑ ΑΕ`;
  return (
    <>
      { basePageTitle && <Helmet defaultTitle={basePageTitle} titleTemplate={`%s | ${basePageTitle}`} /> }

      { profile.isFetched ? <Switch>
        <Route path="/vendor" exact>
          <RedeemForm />
        </Route>

        <Route path="/vendor/history/:pageKey(page)?/:page(\d)?">
          <RedemptionsHistory vendor filter={filter} />
        </Route>

        {/* <Route path="/vendor/offers">
          <>
            <Helmet title="Διαχείριση προσφορών" />
            <Container textAlign="center">
              <Header as="h1" className="colored atmgreen">Διαχείριση προσφορών</Header>
              <Divider className="orange" />
              <Divider hidden />
            </Container>

            <Container text>
              <VendorOffers vendorId={business?.id} />
            </Container>
          </>
        </Route> */}

        <Route path="/vendor/profile">
          <>
            <Helmet title="Διαχείριση στοιχείων καταστήματος" />
            <Container textAlign="center">
              <Header as="h1" className="colored atmgreen">Διαχείριση στοιχείων καταστήματος</Header>
              <Divider className="orange" />
              <Divider hidden />
            </Container>
            
            <Container text>
              <Vendor vendorId={business?.id} />
            </Container>
          </>
        </Route>

        <Route path="*">
          <Redirect to="/vendor" />
        </Route>

      </Switch> : <Loader inline="centered" size="massive" active /> }

      <Divider hidden />

    </>
  );
}

export default RewardsVendorHome;
