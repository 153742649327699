import { Link } from 'react-router-dom';
import { Header, Image, List, Segment, Grid, Icon } from 'semantic-ui-react';

import diadymaImg from '../images/DIADYMA_L_WHITE.png';
import symbiolabsImg from '../images/SymbiolabsLogo.png';

import qs from 'qs';


function RewardsFooter() {
  return (
    <>
      <Segment basic className="footer-menu" inverted>
        <Grid doubling stackable container columns={4} style={{ marginTop: 0, marginBottom: 0}}>
          <Grid.Column>
            <Image href="https://diadyma.gr" src={diadymaImg} />
          </Grid.Column>
            
          <Grid.Column>
            <Header className="footer-header" inverted>Σχεδιασμός και ανάπτυξη</Header>
            <Image size="small" href="https://symbiolabs.gr" src={symbiolabsImg} />
          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Επικοινωνία</Header>
            <List items={[
              '6ο χλμ. Κοζάνης - Πτολεμαΐδας',
              'Κόμβος Εγνατίας',
              'Τ.Κ. 50 100 - Τ.Θ. 155',
            ]} />

            <List items={[
              {
                key: 'tel',
                icon: <Icon className="t green-icon" />,
                content: '24610 45531 - 24610 45533',
              },
              {
                key: '',
                icon: <Icon className="f green-icon" />,
                content: '24610 45532',
              },
            ]} />
            
            <List items={[{
              key: 'email',
              icon: <Icon name="mail outline" className="green-icon" />,
              content: 'zerowaste@diadyma.gr',
            }]} />

          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Site map</Header>
            <List inverted className="footer-links" items={[
              { key: 'offers', content: <Link to="/offers" className="footer-link">Προσφορες</Link> },
              { key: 'points', content: <Link to="/points" className="footer-link">Διαδικασια επιβραβευσης</Link> },
              { key: 'questions', content: <Link to="/questions" className="footer-link">Συχνες ερωτησεις</Link> },
              { key: 'news', content: <Link to="/news" className="footer-link">Νεα</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'vendor-signup', content: <Link to="/signup" className="footer-link">Εγγραφη καταστηματος</Link> },
              { key: 'vendor-signin', content: <Link to={{ pathname: '/vendor', search: `?${qs.stringify({ cancelTo: window.location.pathname })}` }} className="footer-link">Συνδεση καταστηματος</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'admin', content: <Link to={{ pathname: '/admin', search: `?${qs.stringify({ cancelTo: window.location.pathname })}` }} className="footer-link">Περιβαλλον διαχειρισης</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'guide', content: <a href={`${process.env.PUBLIC_URL}/Rewards_User_Manual.pdf`} target="_blank" rel="noreferrer" className="footer-link">Οδηγιες χρησης</a> },
              { key: 'terms', content: <Link to="/terms" className="footer-link">Οροι και προϋποθεσεις</Link> },
            ]} />

          </Grid.Column>

        </Grid>
      </Segment>
    </>
  );
}

export default RewardsFooter;
