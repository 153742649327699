import { Popup } from 'semantic-ui-react';

export default function PopupOnMutationError({ component, mutation, content }) {
  return (
    <Popup trigger={component}
      open={mutation.isError} on="click" onClose={() => mutation.reset()}
      content={content}
    />
  );
}
