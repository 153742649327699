import { useMemo, useRef, useState } from 'react';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { Label, Container, Grid, Header, List, Segment, Statistic, StatisticGroup, Sticky, Divider, Message } from 'semantic-ui-react';
import { Cell, Tooltip, ResponsiveContainer, XAxis, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';


import { Offer } from './Offers';

import dayjs from 'dayjs';
import 'dayjs/locale/el';
import axios from 'axios';

import { DateFilter } from '@zerowaste/components';

dayjs.locale('el')

const couponStates = {
  'activated': {
    label: 'Ενεργοποιήθηκαν',
    color: 'green',
  },
  'redeemed': {
    label: 'Εξαργυρώθηκαν',
    color: 'orange',
  },
  'expired': {
    label: 'Έληξαν',
    color: 'darkorchid',
  },
  'cancelled': {
    label: 'Ακυρώθηκαν',
    color: 'red',
  },
  // 'valid': {
  //   label: 'Ενεργά',
  //   color: 'lightgreen',
  // },
  // 'inactive': {
  //   label: 'Προς επεξεργασία',
  //   color: 'grey',
  // },
  // 'created': {
  //   label: 'Εκδόθηκαν',
  //   color: 'black',
  // }
};


function RewardsStatistics({counts, ...props}) {
  return (
    <StatisticGroup {...props}>
      <Statistic color="green" label="Ενεργοποιηθηκαν" value={counts?.activated} />
      <Statistic color="yellow" label="Εξαργυρωθηκαν" value={counts?.redeemed} />
      <Statistic color="violet" label="Εληξαν" value={counts?.expired} />
      <Statistic color="red" label="Ακυρωθηκαν" value={counts?.cancelled} />
    </StatisticGroup>
  );
}


function RewardsDashboard() {
  const match = useRouteMatch();
  const history = useHistory();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);



  const dateParams = useMemo(() => {
    return {
      date_after: dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : undefined,
      date_before: dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : undefined,
    };
  }, [dateFrom, dateTo]);

  const { data: couponCounts } = useQuery(
    ['coupon_counts', dateParams],
    () => axios.get('/api/coupons/counts/', { params: {...dateParams} }).then(({data}) => data)
  );
  
  const countData = useMemo(
    () => couponCounts &&
      Object.entries(couponCounts).filter(([status]) => status in couponStates).map(
        ([status, value]) => ({ status, label: couponStates[status].label, value })
      ),
    [couponCounts]
  );

  const { data: pointsSums } = useQuery(
    ['points_sums', dateParams],
    () => axios.get('/api/coupons/points/', { params: {...dateParams} }).then(({data}) => data)
  );

  const { data: topOffers } = useQuery(
    ['top_offers', dateParams],
    () => axios.get('/api/offers/popular/', { params: {...dateParams} }).then(({data}) => data)
  );

  const handleOfferClose = () => history.push(match.url);

  const { data: offerCounts } = useQuery(
    ['offer_counts', dateParams],
    () => axios.get('/api/offers/counts/', { params: {...dateParams} }).then(({data}) => data)
  );

  const { data: vendorCounts } = useQuery(
    ['vendor_counts', dateParams],
    () => axios.get('/api/vendors/counts/', { params: {...dateParams} }).then(({data}) => data)
  );

  const [vendorTotals, vendorTotalsAreZero] = useMemo(
    () => {
      if (!vendorCounts) return [undefined, false];

      const { active, inactive } = vendorCounts;
      if (!active && !inactive) return [undefined, true];

      const vendorTotals = [
        { status: 'active', value: active, label: 'Με μία ή παραπάνω προσφορές'},
        { status: 'inactive', value: inactive, label: 'Χωρίς προσφορές'},
      ];

      return [vendorTotals, false];
    },
    [vendorCounts]
  );

  const { data: topParticipants } = useQuery(
    ['top_participants', dateParams],
    () => axios.get('/api/participants/active/', { params: {...dateParams} }).then(({data}) => data)
  );

  const contextRef = useRef();
  const pageTitle = 'Επισκόπηση στατιστικών χρήσης';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />

      <Sticky context={contextRef}>
          <DateFilter dateFrom={dateFrom} setDateFrom={setDateFrom} dateTo={dateTo} setDateTo={setDateTo} />
      </Sticky>

      <Divider hidden />

      <div ref={contextRef}>
      <Grid columns={1} stretched>
        <Grid.Column>
          <Segment basic color="green" textAlign="center">
            <Header size="large" textAlign="center" content="Κουπόνια" />
            <Segment raised>
              <Grid columns="equal">
                <Grid.Column only="tablet computer">
                  <RewardsStatistics counts={couponCounts} widths={4} />
                </Grid.Column>
                <Grid.Column only="mobile">
                  <RewardsStatistics counts={couponCounts} widths={1} />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment raised>
              <ResponsiveContainer height={300}>
                <BarChart width={600} height={300} data={countData} barCategoryGap="20%">
                  <XAxis dataKey="label" />
                  <Tooltip cursor={false} />
                  <Bar name="Κουπόνια" dataKey="value">
                    { countData?.map(({ status }, index) => (
                        <Cell key={`cell-${index}`} fill={couponStates[status].color}  />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Segment>

          </Segment>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Segment basic color="olive">
            <Header size="large" textAlign="center" content="Πόντοι" />
            <Segment raised>
              <StatisticGroup widths={2}>
                <Statistic color="green" label="Ενεργοποιηθηκαν" value={pointsSums?.activated} />
                <Statistic color="yellow" label="Εξαργυρωθηκαν" value={pointsSums?.redeemed} />
              </StatisticGroup>
            </Segment>
          </Segment>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Segment basic color="blue">
            <Header size="large" textAlign="center" content="Προσφορές" />

            <Segment raised>
              <Header>Οι 10 πιο δημοφιλείς προσφορές</Header>
              <List size="large" divided>
                { topOffers?.map((offer, index) =>
                    <List.Item key={offer.id}>
                      <Label content={index + 1} circular color="blue" style={{ marginRight: '1em', width: '2.5em', textAlign: 'center' }} />
                      <Link to={`${match.url}/offers/${offer.id}`}>{offer.title}</Link>
                      <List.Content floated="right">
                        <Label pointing="left" size="large" color="teal" content={`${offer.activated_coupons_count} κουπόνια`} />
                      </List.Content>
                    </List.Item>
                  )
                }
              </List>
              { topOffers?.length === 0 &&
                <Message content="Δεν υπάρχουν ενεργοποιήσεις κουπονιών." />
              }
            </Segment>
            
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="purple">
            <Header size="large" textAlign="center" content="Καταστήματα" />
            <Grid columns={2} stackable>
              <Grid.Column>
                <Segment raised>
                  <Header content="Εγγεγραμμένα καταστήματα" />
                  { vendorTotalsAreZero ?
                    <Message content="Δεν υπάρχουν εγγραφές καταστημάτων." />
                    :
                    <ResponsiveContainer height={256}>
                      <PieChart width={512} height={256}>
                        <Pie data={vendorTotals} dataKey="value" nameKey="label" cx="50%" cy="50%" innerRadius={50} outerRadius={80} fill="#8884d8">
                            {
                              vendorTotals?.map(({status}, index) => <Cell key={`cell-${index}`} fill={status === 'active' ? 'green' : 'brown'} />)
                            }
                        </Pie>
                        <Tooltip/>
                        <Legend layout="vertical" />
                      </PieChart>
                    </ResponsiveContainer>
                  }
                </Segment>
              </Grid.Column>

              <Grid.Column>
                <Segment raised textAlign="center">
                  <Statistic color="blue" label="Συνολικα ενεργες προσφορες" value={offerCounts?.enabled} />
                </Segment>
              </Grid.Column>
            </Grid>

          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="teal">
            <Header size="large" textAlign="center" content="Χρήστες" />
            <Segment raised>
              <Header>Οι 10 πιο ενεργοί χρήστες</Header>
              <List size="large" divided>
                { topParticipants?.map((u, index) =>
                    <List.Item key={u.email}>
                      <Label content={index + 1} circular color="blue" style={{ marginRight: '1em', width: '2.5em', textAlign: 'center' }} />
                      {u.email}
                      <List.Content floated="right">
                        <Label pointing="left" size="large" color="teal" content={`${u.activated_coupons_count} κουπόνια`} />
                      </List.Content>
                    </List.Item>
                  )
                }
              </List>
              { topParticipants?.length === 0 &&
                <Message content="Δεν υπάρχουν ενεργοποιήσεις κουπονιών." />
              }
            </Segment>
          </Segment>
        </Grid.Column>

      </Grid>
      </div>
      
      <Route path={`${match.path}/offers/:offerId(\\d+)?`}>
        <Offer dimmer="inverted" onClose={handleOfferClose} />
      </Route>
      
    </Container>
  );

}

export default RewardsDashboard;
