import { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, Route, Switch, Redirect, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Button, Container, Divider, Form, Grid, Header, Icon, Label, List, Menu, Message, Modal, Rail, Segment, Table } from 'semantic-ui-react';
import { Loader }  from 'semantic-ui-react';

import { ConfirmPopup, SearchFilterInput, useDebounce } from '@zerowaste/components';

import RedemptionsHistory from './RedemptionsHistory';
import { PasswordChangeForm } from './PasswordReset';

import { useOffer, useOffers } from '../queries/offers';
import { useVendors } from '../queries/vendors';

import axios from 'axios';
import qs from 'qs';


function OfferForm({ admin, vendorId, baseUrl, onClose, ...props }) {
  const history = useHistory();
  const { offerId } = useParams();

  const queryClient = useQueryClient();

  const { isFetched, data: offer } = useOffer(offerId,
    { enabled: offerId !== 'new'}
  );

  const [formChanged, setFormChanged] = useState();
  const [offerData, setOfferData] = useState(() => ({
    business: vendorId,
    required_points: 100,
    days_until_expiration: 7,
  }));

  useEffect(
    () => {
      if (isFetched) {
        // don't keep the business data, only the id
        setOfferData({...offer, business: vendorId});
        setFormChanged(false);
      }
  }, [isFetched, vendorId, offer]);

  const createMutation = useMutation(
    (d) => axios.post('/api/offers/', d).then(({data}) => data),
    { onSuccess: ({ id }) => {
      queryClient.invalidateQueries(['offers', { vendorId }]);
      history.replace(`${baseUrl}/${id}`);
    } }
  );
  const saveMutation = useMutation(
    (d) => axios.patch(`/api/offers/${offerId}/`, d).then(({data}) => data),
    { onSuccess: (data) => {
      queryClient.setQueryData(['offer', offerId], data);
      queryClient.invalidateQueries(['offers', { vendorId }]);
      onClose?.();
    } }
  );
  const enableMutation = useMutation(
    () => axios.patch(`/api/offers/${offerId}/`, { hidden: false }).then(({data}) => data),
    { onSuccess: (data) => {
      queryClient.setQueryData(['offer', offerId], data);
      queryClient.invalidateQueries(['offers', { vendorId }]);
    } }
  );
  const disableMutation = useMutation(
    ({ cancel }) => axios.post(`/api/offers/${offerId}/deactivate/`, { cancel }).then(({data}) => data),
    { onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId]);
      queryClient.invalidateQueries(['offers', { vendorId }]);
    } }
  );
  const toggleMutation = useMutation(
    () => axios.post(`/api/offers/${offerId}/toggle/`).then(({data}) => data),
    { onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId]);
      queryClient.invalidateQueries(['offers', { vendorId }]);
    } }
  )

  const destroyMutation = useMutation(
    () => axios.delete(`/api/offers/${offerId}/`),
    { onSuccess: () => {
      queryClient.invalidateQueries(['offers', { vendorId }]);
      onClose?.(true);
    } }
  );

  const handleSave = () => {
    if (offerId === 'new') {
      createMutation.mutate(offerData);
    } else {
      saveMutation.mutate(offerData);
    }
  };

  const handleChange = (e, { name, value }) => {
    setFormChanged(true);
    setOfferData((d) => ({ ...d, [name]: value }));
  };

  const handleEnable = () => enableMutation.mutate();
  const handleDisable = () => admin ? disableMutation.mutate({ cancel: false }) : toggleMutation.mutate();
  const handleDisableAndCancel = () => disableMutation.mutate({ cancel: true });
  const handleDestroy = () => destroyMutation.mutate();

  return (
    <Modal size="small" open onClose={onClose} {...props}>
      <Modal.Header>{ offerId === 'new' ? 'Καταχώρηση νέας προσφοράς' : 'Μεταβολή προσφοράς' }</Modal.Header>
      <Modal.Content>
        <Form id="new-offer" onSubmit={handleSave}>
          <Form.Input required
            label="Τιτλος"
            name="title"
            value={offerData.title} onChange={handleChange}
          />
          <Form.TextArea 
            label="Περιγραφή"
            name="description"
            value={offerData.description} onChange={handleChange}
          />
          <Form.Input required
            label="Πόντοι"
            name="required_points"
            input={{ type: 'number', step: 10, min: 10 }}
            value={offerData.required_points}
            onChange={handleChange}
          />
          <Form.Input required
            label="Διάρκεια ισχύος κουπονιού"
            name="days_until_expiration"
            input={{ type: 'number', step: 1, min: 1 }}
            value={offerData.days_until_expiration}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        { !enableMutation.isLoading && offer?.coupons_count === 0 &&
          <Button floated="left" negative
            content={offer?.hidden === true ? 'Διαγραφή' : 'Απενεργοποίηση'}
            onClick={offer?.hidden === true ? handleDestroy : handleDisable}
            loading={disableMutation.isLoading || destroyMutation.isLoading}
          />
        }
        { !enableMutation.isLoading && offer?.coupons_count > 0 && offer?.hidden === false && (
            offer?.pending_coupons_count > 0 ?
              <ConfirmPopup flowing position="top left" 
                trigger={
                  <Button floated="left" negative
                    content="Απενεργοποίηση"
                    loading={disableMutation.isLoading}
                  />
                }
                header=""
                error={disableMutation.isError && 'Δεν ήταν δυνατή η απενεργοποίηση της προσφοράς.'}
                onDismissError={() => disableMutation.reset()}
                positive={ admin ? 'Με ακύρωση' : 'Απενεργοποίηση' } onConfirm={admin ? handleDisableAndCancel : handleDisable}
                secondary={ admin && 'Χωρίς ακύρωση'} onSecondaryConfirm={admin && handleDisable}
                negative="Επιστροφή"
              >
                <div style={{ maxWidth: admin ? '30em' : '20em' }}>
                  <p>
                    Υπάρχ{ offer?.pending_coupons_count === 1 ? 'ει ' : 'ουν '}
                    { offer?.pending_coupons_count }
                    &nbsp;κουπόνι{ offer?.pending_coupons_count > 1 && 'α ' }
                    &nbsp;που εκκρεμ{ offer?.pending_coupons_count === 1 ? 'εί ' : 'ούν ' }
                    ακόμα.
                  </p>
                  
                  { admin ?
                      <p>
                        Μπορείτε να τ{ offer?.pending_coupons_count === 1 ? 'ο ' : 'α '} ακυρώσετε άμεσα,
                        και οι χρήστες θα πιστωθούν τους αντίστοιχους πόντους.
                      </p>
                    : <>
                        <p>
                          Μπορείτε να απεργοποιήσετε την προσφορά, αλλά {offer?.pending_coupons_count === 1 ? 'το κουπόνι αυτό ' : 'τα κουπόνια αυτά '}
                          θα εξακολουθ{offer?.pending_coupons_count === 1 ? 'εί ' : 'ούν '}
                          να είναι έγκυρ{offer?.pending_coupons_count === 1 ? 'ο' : 'α'},
                          μέχρις ότου λήξ{offer?.pending_coupons_count === 1 ? 'ει' : 'ουν'},
                          ακυρωθ{offer?.pending_coupons_count === 1 ? 'εί' : 'ούν'}, ή εξαργυρωθ{offer?.pending_coupons_count === 1 ? 'εί' : 'ούν'}.
                        </p>
                        <p>
                          Αν επιθυμείτε και την ακύρωση
                          {offer?.pending_coupons_count === 1 ? ' του κουπονιού αυτού' : 'των κουπονιών αυτών'},
                          παρακαλούμε επικοινωνήστε μαζί μας.
                        </p>
                      </>
                  }

                </div>
                
              </ConfirmPopup>
            : <Button floated="left" negative
                content="Απενεργοποίηση"
                onClick={handleDisable}
                loading={disableMutation.isLoading}
              />
        ) }
        { offer?.hidden === true && <Button positive content="Ενεργοποίηση" onClick={handleEnable} loading={enableMutation.isLoading} /> }
        <Button form="new-offer" primary content={offerId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'} loading={createMutation.isLoading || saveMutation.isLoading } disabled={!formChanged} />
        <Button onClick={() => onClose?.()} content={ offerId === 'new' ? 'Ακύρωση' :'Επιστροφή' } />
      </Modal.Actions>
    </Modal>
  );
}

export function VendorOffers({ admin, vendorId }) {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { isLoading, data: offers } = useOffers({ vendorId, enabledOnly: false });

  const handleOfferClose = (deleted) => {
    if (deleted) {
      history.replace(match.url);
    } else {
      history.push(match.url);
    }
  }

  return (
    <Segment basic loading={isLoading}>
      <Table celled selectable className="pointable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Τίτλος</Table.HeaderCell>
            <Table.HeaderCell>Πόντοι</Table.HeaderCell>
            <Table.HeaderCell>Ημέρες ισχύος κουπονιού</Table.HeaderCell>
            <Table.HeaderCell>Ενεργή</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { offers?.map(({ id, title, required_points, days_until_expiration, hidden }) =>
              <Table.Row key={id} onClick={() => history.push(`${match.url}/${id}`)}>
                <Table.Cell>{title}</Table.Cell>
                <Table.Cell textAlign="right">{required_points}</Table.Cell>
                <Table.Cell textAlign="right">{days_until_expiration}</Table.Cell>
                <Table.Cell textAlign="center">{ !hidden && <Icon name="check" color="green" />}</Table.Cell>
              </Table.Row>
            )
          }
          { offers?.length === 0 && 
            <Table.Row>
              <Table.Cell colSpan={4} textAlign="center">
                Δεν υπάρχουν καταχωρημένες προσφορές για το κατάστημα
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
      </Table>

      <Button floated="right" positive icon="plus" labelPosition="left" content="Προσθήκη προσφοράς" as={Link} to={`${match.url}/new`} />
      <Divider clearing hidden />

      <Route path={`${match.path}/:offerId(\\d+|new)`}>
        <OfferForm key={location.key} admin={admin} vendorId={vendorId} dimmer="inverted" onClose={handleOfferClose} baseUrl={match.url} />
      </Route>
    </Segment>
  );
}

export function Vendor({ vendorId, setFinishedMessage }) {
  const history = useHistory();
  const match = useRouteMatch();
  const queryClient = useQueryClient();

  const params = useParams();
  const isVendorProfile = !params.vendorId;
  if (!vendorId) {
    vendorId = params.vendorId;
  }

  const { isFetched, isLoading, data: vendor } = useQuery(['vendor', { vendorId }], () =>
    axios.get(`/api/vendors/${vendorId}/`).then(({data}) => data)
  );

  const [formChanged, setFormChanged] = useState(false);
  const [vendorData, setVendorData] = useState(() => ({
    name: '',
    municipality: null,
    adderss: '',
    telephone: '',
  }));
  useEffect(
    () => {
      if (isFetched) {
        setVendorData(vendor);
        setFormChanged(false);
      }
  }, [isFetched, vendor]);

  const saveMutation = useMutation(
    (data) => axios.patch(`/api/vendors/${vendorId}/`, data).then(({data}) => data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('vendors');
        queryClient.setQueryData(['vendor', { vendorId }], data);
      },
    }
  );

  const enableVendor = useMutation(
    () => axios.post(`/api/vendors/${vendorId}/enable/`),
    {
      onSuccess: (data) => {
        setFinishedMessage('Το κατάστημα ενεργοποιήθηκε.');
        queryClient.invalidateQueries('vendors');
        queryClient.invalidateQueries(['vendor', { vendorId }]);
        history.replace(`/admin/vendors/${vendorId}`);
      },
    }
  );
  const disableVendor = useMutation(
    () => axios.post(`/api/vendors/${vendorId}/disable/`),
    {
      onSuccess: () => {
        setFinishedMessage('Το κατάστημα απενεργοποιήθηκε.');
        queryClient.invalidateQueries('vendors');
        queryClient.invalidateQueries(['vendor', { vendorId }]);
        history.replace(`/admin/vendors/disabled/${vendorId}`);
      },
    }
  );
  const destroyVendor = useMutation(
    () => axios.delete(`/api/vendors/${vendorId}/`),
    {
      onSuccess: () => {
        setFinishedMessage('Το κατάστημα διαγράφηκε.');
        queryClient.invalidateQueries('vendors');
        history.replace('../disabled'); // we could only destroy from the /disabled route
      },
    }
  );

  const handleChange = (e, { name, value }) => {
    setFormChanged(true);
    setVendorData((d) => ({ ...d, [name]: value }));
  };

  const handleSubmit = () => saveMutation.mutate(vendorData);
  const handleReset = () => {
    setVendorData(vendor);
    setFormChanged(false);
  };

  const { data: municipalities } = useQuery('municipalities', () => axios.get('/api/municipalities/').then(({data}) => {
    // transform to options format
    return data.map((m) => ({ value: m.id, text: m.name }));
  }), { initialData: [] });

  return (
    <>
      { params.vendorId && 
        <Menu secondary pointing size="huge">
          <Menu.Item name="details" as={NavLink} to={match.url} exact>
            Στοιχεία καταστήματος
          </Menu.Item>
          <Menu.Item name="offers" as={NavLink} to={`${match.url}/offers`}>
            Προσφορές
          </Menu.Item>
          <Menu.Item name="coupons" as={NavLink} to={`${match.url}/coupons`} disabled={!vendor?.is_active}>
            Εκκρεμή κουπόνια
          </Menu.Item>
        </Menu>
      }

      <Switch>

        <Route path={`${match.path}/offers`}>
          <VendorOffers admin vendorId={vendorId} />
        </Route>

        { vendor?.is_active === true &&
          <Route key="coupons" path={`${match.path}/coupons/:pageKey(page)?/:page(\\d+)?`}>
            <RedemptionsHistory admin />
          </Route>
        }

        { vendor?.is_active === false &&
          <Route key="coupons" path={`${match.path}/coupons`}>
            <Redirect to={`${match.url}`} />
          </Route>
        }

        <Route path={match.path} exact>
          <Segment secondary clearing disabled={isLoading}>
            <Loader active={isLoading} />
            <Label ribbon="right" color="teal" size="large">{ vendor ? `Email υπευθύνου: ${vendor.email}` : <>&nbsp;</>}</Label>
            <Form onSubmit={handleSubmit}>
              <Form.Input label="Όνομα επιχείρησης" name="name" value={vendorData.name} onChange={handleChange} />
              <Form.Dropdown selection
                label="Δήμος"
                name="municipality"
                value={vendorData.municipality || null}
                onChange={handleChange}
                options={municipalities}
              />

              <Form.TextArea rows={2} label="Διεύθυνση" name="address" value={vendorData.address} onChange={handleChange} />
              <Form.Input label="Τηλέφωνο" input={{ inputMode: 'tel' }} name="telephone" value={vendorData.telephone} onChange={handleChange} />

              <Divider />

              <Form.Group widths={2}>
                <Form.Input label="Εκδοθέντα κουπόνια" readOnly value={vendor?.coupons_count || 0} />
                <Form.Input label="Εκκρεμή κουπόνια" readOnly value={vendor?.pending_coupons_count || 0} />
              </Form.Group>

              <Menu text>
                { isVendorProfile &&
                  <Menu.Item>
                    <Button secondary content="Αλλαγή συνθηματικού" as={Link} to={`${match.url}/password`} />
                  </Menu.Item>
                }

                { !isVendorProfile && vendor?.is_active === false && vendor?.coupons_count === 0 &&
                  <Menu.Item>
                    <ConfirmPopup position="top center"
                      trigger={<Button type="button" negative content="Διαγραφή" loading={destroyVendor.isLoading} /> }
                      onConfirm={() => destroyVendor.mutate()}
                      error={destroyVendor.isError && 'Δεν ήταν δυνατή η διαγραφή του καταστήματος'}
                      onDismissError={() => destroyVendor.reset()}
                      positive="Διαγραφή" negative="Επιστροφή"
                    >
                      <Header size="small" dividing>Διαγραφή καταστήματος</Header>
                      <p>
                        Μπορείτε να διαγράψετε το κατάστημα, καθώς δεν έχουν εκδοθεί ποτέ κουπόνια για κάποια προσφορά του.
                        Η διαδικασία είναι <strong>μη αντιστρέψιμη</strong> και ενδείκνυται παρά μόνο σε εξαιρετικές περιπτώσεις.
                        Οι πληροφορίες για τυχόν υπάρχουσες προσφορές θα διαγραφούν επίσης.
                      </p>
                    </ConfirmPopup>
                  </Menu.Item>
                }

                { !isVendorProfile && vendor?.is_active === true &&
                  <Menu.Item>
                    <ConfirmPopup position="top center" wide
                      trigger={<Button type="button" negative content="Απενεργοποίηση" loading={disableVendor.isLoading} />}
                      onConfirm={() => disableVendor.mutate()}
                      error={disableVendor.isError && 'Δεν ήταν δυνατή η απενεργοποίηση του καταστήματος'}
                      onDismissError={() => disableVendor.reset()}
                      positive="Απενεργοποίηση" negative="Επιστροφή"
                    >
                      <Header size="small" dividing>Απενεργοποίηση καταστήματος</Header>
                      <p>
                        Με την απενεργοποίηση του καταστήματος, δεν θα είναι πλέον δυνατή η σύνδεση 
                        στην πλατφόρμα με χρήση του email <strong>{vendor.email}</strong>, εκτός και αν ενεργοποιήσετε εκ νέου
                        το κατάστημα.
                      </p>
                      <p>
                        Τυχόν ενεργές προσφορές του καταστήματος θα απενεργοποιηθούν επίσης.
                        { vendor.pending_coupons_count > 0 && <>
                          {' '}
                          Υπάρχ{ vendor.pending_coupons_count === 1 ? 'ει ' : 'ουν ' }
                          <strong>{ vendor.pending_coupons_count } κουπόνι{ vendor.pending_coupons_count === 1 ? ' ' : 'α ' }</strong>
                          που εκκρεμ{ vendor.pending_coupons_count === 1 ? 'εί' : 'ούν' }.
                          Θα ακυρωθ{ vendor.pending_coupons_count === 1 ? 'εί' : 'ούν' } άμεσα,
                          και οι χρήστες θα πιστωθούν τους αντίστοιχους πόντους.
                        </> }
                      </p>
                    </ConfirmPopup>
                  </Menu.Item>
                }

                <Menu.Menu position="right">
                  { !isVendorProfile && vendor?.is_active === false &&
                    <Menu.Item>
                      <ConfirmPopup position="right center"
                        trigger={<Button type="button" positive content="Ενεργοποίηση" loading={enableVendor.isLoading} />}
                        onConfirm={() => enableVendor.mutate()}
                        onDismissError={() => enableVendor.reset()}
                      />
                    </Menu.Item>
                  }
                  <Menu.Item>
                    <Form.Button primary content="Ενημέρωση" disabled={!formChanged} loading={saveMutation.isLoading} />
                  </Menu.Item>
                  <Menu.Item>
                    <Form.Button type="reset" content="Επαναφορά" onClick={handleReset} disabled={!formChanged} />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>

            </Form>
          </Segment>
        </Route>

      </Switch>

      { isVendorProfile &&
        <Route path={`${match.path}/password`}>
          <PasswordChangeForm />
        </Route>
      }

    </>
  );
}

function LinkListItem({ filter, filterActive, idTo, filterTo, ...props }) {
  return <List.Item
    active={filter ? filterActive : undefined }
    as={filter ? Link : NavLink }
    to={filter ? filterTo : idTo}
    {...props}
  />;
}

export function VendorsList({ as: Root=Fragment, listHeight, parentPageTitle, parentMatch, pathSuffix='', filter, ...props }) {
  const match = useRouteMatch();
  const showDisabled = match.path.endsWith('disabled');

  let { vendor: activeVendor, participant } = filter || {};
  activeVendor = activeVendor && Number.parseInt(activeVendor);

  const [vendorSearch, setVendorSearch] = useState('');
  const debouncedVendorSearch = useDebounce(vendorSearch, 300);

  const vendors = useVendors({ participant, search: debouncedVendorSearch, is_active: filter ? true : !showDisabled });
  const noVendors = !debouncedVendorSearch && vendors.data?.length === 0;

  const vendorsStats = useQuery(['vendors_stats', { participant }],
    () => axios.get('/api/vendors/counts/', { params: { participant }}).then(({data}) => data)
  );
  const totalCoupons = vendorsStats.data?.coupons_count;

  const searchInput = (
    <SearchFilterInput
      loading={vendors.isFetching}
      disabled={!debouncedVendorSearch && vendors.data?.length === 0}
      value={vendorSearch}
      setValue={setVendorSearch}
    />
  );

  return (
    <Root {...props}>
      {/* override title only if disabled are selected */}
      { showDisabled && <Helmet title="Διαχείριση ανενεργών καταστημάτων" /> }

      { !filter && <>
        <Menu secondary pointing size="huge">
          <Menu.Item as={NavLink} to={parentMatch.url} exact={showDisabled} content="Ενεργά" />
          <Menu.Item as={NavLink} to={`${parentMatch.url}/disabled`} content="Ανενεργά" />
        </Menu>
        { searchInput }
      </> }

      <List selection size="large" className="narrow-list">
        { filter && <>
          <List.Item
            active={!activeVendor}
            as={Link} to={{
              pathname: parentMatch.url,
              search: '?' + qs.stringify({ ...filter, vendor: undefined}),
            }}
          >
            <List.Content floated="right">
              <Label color="blue" title={totalCoupons !== undefined ? `${totalCoupons} κουπόνια` : undefined}>
                { totalCoupons !== undefined ? totalCoupons : <>&hellip;</> }
              </Label>
            </List.Content>
            <List.Header content="Όλα τα καταστήματα" />
          </List.Item>
          <List.Item>
            { searchInput }
          </List.Item>
        </> }
      </List>

      <div style={{position: 'relative', height: listHeight, overflow: listHeight ? 'auto' : undefined }}>

        { vendors.isLoading && <Loader active /> }
        { vendors.isError && <Message error header="Οι πληροφορίες για τα καταστήματα δεν είναι διαθέσιμες." /> }
        { noVendors && <Message header={`Δεν υπάρχουν ${filter ? 'εγγεγραμμένα' : (showDisabled ? 'ανενεργά' : 'ενεργά' )} καταστήματα.`} /> }

        <List selection divided className="narrow-list" size="large">
          { vendors.data?.map(({ id, name, address, is_active, coupons_count }) => <>
              {/* Add vendor name to page title, by using a route-based head rendering method */}
              <Route path={`${parentMatch.path}/${showDisabled ? 'disabled/' : ''}${id}`}>
                <Helmet title={`${name} | ${parentPageTitle}`} />
              </Route>
              <Route path={`${parentMatch.path}/${showDisabled ? 'disabled/' : ''}${id}/offers`}>
                <Helmet title={`Προσφορές | ${name} | ${parentPageTitle}`} />
              </Route>
              <Route path={`${parentMatch.path}/${id}/coupons`}>
                <Helmet title={`Εκκρεμή κουπόνια | ${name} | ${parentPageTitle}`} />
              </Route>

              <LinkListItem key={id}
                filter={!!filter}
                filterActive={activeVendor === id} 
                filterTo={{ 
                  pathname: parentMatch.url,
                  search: '?' + qs.stringify({ ...filter, vendor: id})
                }}
                idTo={`${match.url}/${id}${pathSuffix}`}
              >
                { filter &&
                  <List.Content floated="right">
                    <Label color="blue" content={coupons_count} title={`${coupons_count} κουπόνια`} />
                  </List.Content>
                }
                <List.Content>
                  <List.Header>{ name }</List.Header>
                  <List.Description>
                    { address }
                  </List.Description>
                </List.Content>
              </LinkListItem>
          </> ) }
        </List>

      </div>

    </Root>
  );
}

export default function VendorsAdmin() {
  const match = useRouteMatch();
  const location = useLocation();

  // is undefined if not found
  const pathSuffix = ['/offers', '/coupons'].find((suffix) => location.pathname.endsWith(suffix));

  const [finishedMessage, setFinishedMessage] = useState();
  useEffect(() => {
    if (finishedMessage) {
      const handle = setTimeout(() => setFinishedMessage(), 5000);
      return () => clearTimeout(handle);
    }
  }, [finishedMessage]);

  const pageTitle = 'Διαχείριση καταστημάτων';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />

      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <Route path={[`${match.path}/disabled`, match.path ]}>
              <VendorsList listHeight="50vh" parentPageTitle={pageTitle} parentMatch={match} pathSuffix={pathSuffix} />
            </Route>
          </Grid.Column>
          <Grid.Column width={12}>
            <Switch>
              <Route path={[`${match.path}/disabled/:vendorId(\\d+)`, `${match.path}/:vendorId(\\d+)`]}>
                <Vendor setFinishedMessage={setFinishedMessage} />
              </Route>
              <Route>
                <Divider hidden />
              </Route>
            </Switch>

            <Segment basic>
              { finishedMessage &&
                <Rail internal style={{ width: '100%' }} as={Container} textAlign="center">
                  <Message compact floating header={finishedMessage} onMouseEnter={(e) => setFinishedMessage((f) => f && `${f} `)} />
                </Rail>
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    </Container>
  )
}
