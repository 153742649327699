import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Header, Container, Divider, Segment, Accordion, List } from 'semantic-ui-react';


export default function FaqPage() {
  const panels = [
    {
      key: 'collect',
      title: 'Πώς μπορώ να συλλέξω πόντους;',
      content: {
        content: (
          <Segment basic padded size="large">
            <List relaxed ordered className="decimal">
              <List.Item>Από την επαναχρησιμοποίηση υλικών:<p />
                  <p>
                    Ο κάθε πολίτης μπορεί είτε να παραδίδει αντικείμενα που δεν είναι απόβλητα και δεν τα χρειάζεται, είτε να αποκτά υλικά από τους χώρους των Κέντρων Δημιουργικής Επαναχρησιμοποίησης Υλικών (ΚΔΕΥ), χωρίς κόστος.
                  </p>

                  <List className="plain" items={[
                    'Το ωράριο επίσκεψης στους χώρους των ΚΔΕΥ είναι:',
                    'Καθημερινά: 08:00-15:00',
                    'Σάββατο: 09:00-13:00',
                  ]} />

                  <p />
                  <Header size="small">Κέντρο Δημιουργικής Επαναχρησιμοποίησης Υλικών Καστοριάς</Header>
                  <List className="plain" items={[
                    'Διεύθυνση: Περιοχή ΒΙΠΑ Καστοριάς 3,5 χλμ Καστοριάς - Μανιάκοι',
                    'Τηλέφωνο επικοινωνίας: 6943240504',
                  ]} />
                
                  <p />
                  <Header size="small" as="p">Κέντρο Δημιουργικής Επαναχρησιμοποίησης Υλικών Φλώρινας</Header>
                  <List className="plain" items={[
                    'Διεύθυνση: 3ο χλμ Ε.Ο. Φλώρινας – Νίκης (μετά τις σχολές ΟΑΕΔ και το εργοτάξιο ΔΕΚΕ)',
                    'Τηλέφωνο επικοινωνίας: 6941572735',
                  ]} />

                  <p>Περισσότερες πληροφορίες στο <a href="https://reuse.diadyma.gr">https://reuse.diadyma.gr</a></p>
                  <p />
              </List.Item>

              <List.Item>Από την ανακύκλωση τηγανέλαιων:<p />
                <p>Ο κάθε πολίτης μπορεί να παραδίδει τηγανέλαια είτε στα ειδικά ΑΤΜ, είτε στα σημεία που υπάρχει ο πορτοκαλί κάδος της ΔΙΑΔΥΜΑ.</p>
                <p>Περισσότερες πληροφορίες στο <a href="https://cookingoils.diadyma.gr">https://cookingoils.diadyma.gr</a></p>
              </List.Item>

            </List>
          </Segment>
        )
      }
    },
    {
      key: 'use',
      title: 'Σε τι χρησιμεύουν οι πόντοι που μαζεύω;',
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Οι πόντοι που συγκεντρώνονται μπορούν να εξαργυρωθούν σε συμβεβλημένα καταστήματα. <Link to="/offers">Δες εδώ τις προσφορές</Link>.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'redeem',
      title: 'Πώς μπορώ να εξαργυρώσω τους πόντους που μαζεύω;',
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Οι πόντοι εξαργυρώνονται με τη μορφή εκπτωτικού κουπονιού, το οποίο θα επιδεικνύεται στο εκάστοτε κατάστημα.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'expiration',
      title: 'Πόσες μέρες διαρκεί το κουπόνι εξαργύρωσης πόντων;',
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Όταν γίνει αίτημα παραλαβής κουπονιού, θα υπάρχει δικαίωμα ακύρωσης του μέχρι τις 12 το βράδυ της ίδιας μέρας. Από την επόμενη ημέρα το κουπόνι θα θεωρείται ενεργό και θα μπορεί να γίνει η εξαργύρωσή του έως και τις επόμενες 7 μέρες. Μετά το πέρας των 7 ημερών το κουπόνι θα πάψει να ισχύει και οι πόντοι που εξαργυρώθηκαν θα χαθούν.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'why',
      title: 'Γιατί να δημιουργήσω λογαριασμό χρήστη χρήστη στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ;',
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Η ΔΙΑΔΥΜΑ ακολουθώντας τα ευρωπαϊκά πρότυπα εντάσσει στη φιλοσοφία της την ανταποδοτική διαχείριση αποβλήτων. Για το λόγο αυτό οι πολίτες της Δυτικής Μακεδονίας που ανακυκλώνουν τηγανέλαια ή χρησιμοποιούν τα Κέντρα Δημιουργικής Επαναχρησιμοποίησης Υλικών θα επιβραβεύονται. Θα έχουν τη δυνατότητα συλλογής πόντων τους οποίους και θα εξαργυρώνουν σε συμβεβλημένα καταστήματα.
            </p>
          </Segment>)
      }
    },
    {
      key: 'diadyma',
      title: 'Ποια είναι η ΔΙΑΔΥΜΑ ΑΕ;',
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Η Ανώνυμη Εταιρεία Διαχείρισης Απορριμμάτων Δυτικής Μακεδονίας (ΔΙΑΔΥΜΑ ΑΕ) ιδρύθηκε το 1998 και είναι ο Φορέας Διαχείρισης Απορριμμάτων (ΦοΔΣΑ) της Περιφέρεια Δυτικής Μακεδονίας.Έχοντας ως προτεραιότητα "Τον Πολίτη στο Περιβάλλον του", αξιοποιήθηκαν οι διαθέσιμοι πόροι και τα πλεονεκτήματα της περιοχής, σχεδιάστηκε ένα ολοκληρωμένο σύστημα διαχείρισης απορριμμάτων, ικανό να προσαρμόζεται στις ολοένα αυξανόμενες απαιτήσεις της περιβαλλοντικής νομοθεσίας. Η επίτευξη των στόχων και των υποχρεώσεων διαδέχονται η μία την άλλη και κατατάσσουν την περιφέρειά μας μεταξύ των "Καλών Πρακτικών" στην διαχείριση των στερεών αποβλήτων, σε επίπεδο Ευρωπαϊκής Ένωσης.
            </p>
          </Segment>
        )
      }
    },
  ];

  const pageTitle = 'Συχνές ερωτήσεις';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
    </Container>

    <Segment basic attached className="colored lightgreen">
      <Container>
        <Divider hidden />
        <Accordion exclusive={false} className="green" panels={panels} />

        <Divider section hidden />
        <Divider className="fat" />
        <Divider hidden />
      </Container>
    </Segment>
  </>);
}
