import { useQuery } from 'react-query';

import axios from 'axios';


export const fetchOffers = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { vendorId, enabledOnly=true }={}] = queryKey;
  return axios.get('/api/offers/', {
    params: {
      business: vendorId,
      hidden: enabledOnly ? false : undefined,
    },
  }).then(({data}) => data);
};

export const fetchOffer = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, offerId] = queryKey;
  return axios.get(`/api/offers/${offerId}/`).then(({data}) => data);
};

export const offersKeys = {
  all: ['offers'],
  list: (vendorId, enabledOnly) => ['offers', { vendorId, enabledOnly }],
  detail: (offerId) => ['offer', offerId],
};

export const useOffers = ({ vendorId, enabledOnly }, config) => {
  let queryKey;
  if (vendorId) {
    queryKey = offersKeys.list(vendorId, enabledOnly);
  } else {
    queryKey = offersKeys.all;
  }

  return useQuery(queryKey, fetchOffers, config);
};

export const useOffer = (offerId, config) => 
  useQuery(offersKeys.detail(offerId), fetchOffer, config);
