import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import { Label, List, Menu, Message } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';

import { SearchFilterInput, useDebounce } from '@zerowaste/components';

import { useParticipants } from '../queries/participants';

import qs from 'qs';
import axios from 'axios';


function LinkListItem({ filter, filterActive, idTo, filterTo, ...props }) {
  return <List.Item
    active={filter ? filterActive : undefined }
    as={filter ? Link : NavLink }
    to={filter ? filterTo : idTo}
    {...props}
  />;
}

export function ParticipantsList({ as: Root=Fragment, listHeight, baseUrl, pathSuffix='', filter, ...props}) {
  let { vendor, participant: activeParticipant } = filter || {};
  activeParticipant = activeParticipant && Number.parseInt(activeParticipant);

  const [participantSearch, setParticipantSearch] = useState('');
  const debouncedParticipantSearch = useDebounce(participantSearch, 300);

  const participants = useParticipants({ vendor, search: debouncedParticipantSearch });
  const noParticipants = !debouncedParticipantSearch && participants.data?.length === 0;

  const participantsStats = useQuery(['participant_stats', { vendor }],
    () => axios.get('/api/participants/counts/', { params: { vendor }}).then(({data}) => data)
  );
  const totalCoupons = participantsStats.data?.coupons_count;

  const searchInput = (
    <SearchFilterInput
      loading={participants.isFetching}
      disabled={!debouncedParticipantSearch && participants.data?.length === 0}
      value={participantSearch}
      setValue={setParticipantSearch}
    />
  );

  return (
    <Root {...props}>
      { !filter && <>
        <Menu secondary pointing size="huge">
          <Menu.Item as={NavLink} to={baseUrl} content="Χρήστες" />
        </Menu>
        { searchInput }
      </> }

      <List selection size="large" className="narrow-list">
        { filter && <>
          <List.Item
            active={!activeParticipant}
            as={Link} to={{
              pathname: baseUrl,
              search: '?' + qs.stringify({ ...filter, participant: undefined }),
            }}
          >
            <List.Content floated="right">
              <Label color="teal" title={totalCoupons !== undefined ? `${totalCoupons} κουπόνια` : undefined}>
                { totalCoupons !== undefined ? totalCoupons : <>&hellip;</>}
              </Label>
            </List.Content>
            <List.Header content="Όλοι οι χρήστες" />
          </List.Item>
          <List.Item>
            { searchInput }
          </List.Item>
        </> }
      </List>

      <div style={{position: 'relative', height: listHeight, overflow: listHeight ? 'auto' : undefined }}>

        { participants.isLoading && <Loader active /> }
        { participants.isError && <Message error content="Οι πληροφορίες για τους χρήστες δεν είναι διαθέσιμες." /> }
        { noParticipants && <Message header="Δεν υπάρχουν εγγεγραμμένοι χρήστες." /> }

        <List selection divided className="narrow-list" size="large">
          { participants.data && participants.data.map(({ id, full_name, email, coupons_count }) => 
              <LinkListItem key={id}
                filter={!!filter}
                filterActive={activeParticipant === id}
                filterTo={{
                  pathname: baseUrl,
                  search: '?' + qs.stringify({ ...filter, participant: id }),
                }}
                idTo={`${baseUrl}/${id}${pathSuffix}`}
              >
                { filter && 
                  <List.Content floated="right">
                    <Label color="teal" content={coupons_count} title={`${coupons_count} κουπόνια`} />
                  </List.Content>
                }
                <List.Content title={`${ full_name || 'Άγνωστο όνομα' } (${email})`}>
                  <List.Header>{ full_name || 'Άγνωστο όνομα' }</List.Header>
                  <List.Description>
                    { email }
                  </List.Description>
                </List.Content>
              </LinkListItem>
          ) }
        </List>

      </div>

    </Root>
  );
}
