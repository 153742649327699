import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Container, Divider, Grid, Header, Image } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import reuseImg from '../images/reuse.png';
import cookingOilsOnlyImg from '../images/cookingoils-only.png';
import { Helmet } from 'react-helmet';


export default function RewardsProcess({ onRegister }) {
  const history = useHistory();
  const profile = useProfile();

  const isStaff = profile.data && !profile.data.participant;

  const handleRegisterButton = useCallback(() => {
    if (profile.data) {
      // logged in
       history.push('/profile');
    } else {
      onRegister();
    }
  }, [profile, history, onRegister]);

  const pageTitle = 'Διαδικασία επιβράβευσης';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Divider hidden />

    <Container text textAlign="center">
      <p>
        Προϋπόθεση για τη συλλογή των πόντων είναι η δημιουργία λογαριασμού χρήστη στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ, με τον οποίο θα έχεις πρόσβαση στις παρακάτω πλατφόρμες:
      </p>
    </Container>

    <Divider section hidden />

    <Container textAlign="center">
      <Grid stackable stretched columns={3}>
        <Grid.Column >
          <Header block size="huge" className="action-header blue"
            as="a" href="https://reuse.diadyma.gr"
          >Επανα&shy;χρησιμοποίησης</Header>
        </Grid.Column>
        <Grid.Column>
          <Header block size="huge" className="action-header orange"
            as="a" href="https://cookingoils.diadyma.gr"
          >Ανακύκλωσης τηγανέλαιων</Header>
        </Grid.Column>
        <Grid.Column>
          <Header block size="huge" className="action-header green"
            as={ isStaff ? Link : 'a' }
            to={ isStaff ? '/admin' : undefined }
            onClick={ isStaff ? undefined : handleRegisterButton }
          >Εξαργύρωσης πόντων</Header>
        </Grid.Column>
      </Grid>
    </Container>

    <Divider section hidden />

    <Container text>

      <Grid stackable lang="el">
        <Grid.Column width={7}>
          <Image centered src={reuseImg} size="medium" />
        </Grid.Column>
        <Grid.Column width={9}>
          <Header as="h3">Από την επαναχρησιμοποίηση</Header>

          <p><strong>Επιβραβεύουμε την επαναχρησιμοποίηση, ως καλή πρακτική διαχείρισης των αποβλήτων!</strong></p>

          <p>
            Επαναχρησιμοποίησε υλικά από τα{' '}
            <a href="https://reuse.diadyma.gr/">Κέντρα Δημιουργικής Επαναχρησιμοποίησης Υλικών Δυτικής Μακεδονίας</a>{' '}
            και μάζεψε πόντους επιβράβευσης για κάθε υλικό που επαναχρησιμοποιείς.
            Με την παράδοση ή την παραλαβή υλικού, το σύστημα συλλογής πόντων σε επιβραβεύει με τους αντίστοιχους πόντους.
            Ο αριθμός των πόντων εξαρτάται από το είδος της συναλλαγής, τα τεμάχια και τον τύπο του υλικού.
            Για περισσότερες πληροφορίες για το σύστημα των πόντων κάνε κλικ{' '}
            <a href="https://reuse.diadyma.gr/generalinfo?systemPoints">εδώ</a>.
          </p>

        </Grid.Column>
      </Grid>

      <Divider section />

      <Grid stackable>
        <Grid.Column width={7}>
          <Image centered src={cookingOilsOnlyImg} size="medium" />
        </Grid.Column>
        <Grid.Column width={9}>
          <Header as="h3">Από την ανακύκλωση τηγανέλαιων</Header>

          <p><strong>Επιβραβεύουμε την ανακύκλωση τηγανέλαιων, ως καλή πρακτική διαχείρισής τους!</strong></p>

          <p>
            Κέρδισε πόντους εύκολα και γρήγορα, κάθε φορά που επιστρέφεις μπουκάλια με χρησιμοποιημένα τηγανέλαια στα ειδικά σημεία συλλογής (πράσινο ATM ή πορτοκαλί κάδος) που βρίσκονται σε διάφορα σημεία στην περιφέρεια. Μην ξεχάσεις να ενεργοποιήσεις τον κωδικό barcode που έχεις παραλάβει. Το αυτοκόλλητο με τον κωδικό τοποθετείται στο καπάκι των ειδικών μπουκαλιών και όταν αυτά γεμίσουν με τηγανέλαια, τα επιστρέφεις στα σημεία συλλογής, από όπου θα μεταφερθούν σε ειδική εγκατάσταση για περαιτέρω επεξεργασία. Όσο περισσότερα λίτρα επιστρέφεις, τόσο περισσότερους πόντους συλλέγεις, ενώ ταυτόχρονα συμβάλλεις στη βιώσιμη διαχείριση των αποβλήτων και στην προστασία του περιβάλλοντος. Για περισσότερες πληροφορίες κάνε κλικ <a href="https://cookingoils.diadyma.gr/">εδώ</a>.
          </p>

        </Grid.Column>
      </Grid>

      <Divider hidden />

      <p><strong>ΣΗΜΕΙΩΣΗ: </strong>Προκειμένου να εμφανιστούν οι πόντοι στον λογαριασμό σας απαιτούνται μερικές ημέρες ώστε να γίνει η ταυτοποίηση στο σημείο επεξεργασίας τηγανέλαιων της ΔΙΑΔΥΜΑ.</p>

      <Divider section hidden />
    </Container>
  </>);
}
