import { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Header, Modal, Form, Message, Button, Divider } from 'semantic-ui-react';

import axios from 'axios';
import { useMutation } from 'react-query';


function PasswordErrorMessage({ keywords, ...props}) {
  const messageLines = [], messageItems = [];

  if (keywords?.includes('password mismatch')) {
    messageLines.push('Η επαλήθευση συνθηματικού δεν ταιριάζει με το συνθηματικό.');
  }

  let passwordError = false;
  if (keywords?.includes('short password')) {
    messageItems.push('Αυτό το συνθηματικό είναι πολύ μικρό. Πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.')
    passwordError = true;
  }
  if (keywords?.includes('common password')) {
    messageItems.push('Πολύ κοινό συνθηματικό.')
    passwordError = true;
  }
  if (keywords?.includes('numeric password')) {
    messageItems.push('Αυτό το συνθηματικό αποτελείται μόνο απο αριθμούς.')
    passwordError = true;
  }
  if (keywords?.includes('similar email password')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με τη διεύθυνση email.');
    passwordError = true;
  }

  if (keywords?.includes('invalid token')) {
    messageLines.push('Ο κωδικός επαναφοράς συνθηματικού δεν ισχύει ή έχει λήξει.');
    messageLines.push(<>Μπορείτε να ζητήσετε εκ νέου επαναφορά του συνθηματικού <Link to="/password/reset">εδώ</Link>.</>);
  }

  if (keywords?.includes('invalid current password')) {
    messageLines.push('Παρακαλώ εισαγάγετε το σωστό ισχύον συνθηματικό.');
  }

  let header = 'Αποτυχία αλλαγής συνθηματικού';
  if (passwordError) {
    header = 'Μη αποδεκτό συνθηματικό';
  }

  return (
    <Message {...props} error>
      <Message.Header>{ header }</Message.Header>
      <Divider />
      { messageLines.length > 0 && 
        <p>
          { messageLines.map((content, index) => <div key={index}>{content}</div> ) }
        </p>
      }
      { messageItems.length > 0 && <Message.List items={messageItems} /> }
    </Message>
  );
}

export function PasswordResetModal(props) {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const resetMutation = useMutation(() => axios.post('/api/auth/users/reset_password/', { email }));

  const handleChange = (e, { value }) => setEmail(value);

  const handleSubmit = (e) => resetMutation.mutate();
  const handleClose = () => history.push('/');

  return (
    <Modal centered={false} size="small" dimmer="inverted" open onClose={handleClose}>
      <Modal.Header>Επαναφορά συνθηματικού</Modal.Header>
      <Modal.Content>
        <Form id="request-password-reset" success={resetMutation.isSuccess} error={resetMutation.isError} onSubmit={handleSubmit}>
          <Header size="small">Εισάγετε το e-mail σας και θα σας αποσταλεί σύνδεσμος για επαναφορά του συνθηματικού σας</Header>

          <Form.Input size="large"
            value={email}
            type="email"
            required
            readOnly={resetMutation.isSuccess}
            onChange={handleChange}
          />

          <Message success>
            <Message.Header>Επιτυχές αίτημα επαναφοράς</Message.Header>
            <Message.Content>
              Εφόσον έχετε λογαριασμό με το email που εισαγάγατε, θα σας σταλεί μήνυμα με έναν σύνδεσμο επαναφοράς του συνθηματικού σας.
            </Message.Content>
          </Message>

          <Message error header="Αποτυχία αιτήματος επαναφοράς συνθηματικού" content="Παρακαλώ προσπαθήστε ξανά αργότερα." />

        </Form>
      </Modal.Content>
      <Modal.Actions>
        { !resetMutation.isSuccess &&
          <Button form="request-password-reset" primary content="Επαναφορά συνθηματικού"
            loading={resetMutation.isLoading}
            disabled={resetMutation.isLoading}
          />
        }
        <Button content="Επιστροφή" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  );
}

export function PasswordChangeForm() {
  const history = useHistory();
  const { uid, token } = useParams();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const passwordMutation = useMutation(
    async ({currentPassword, newPassword, repeatPassword, uid, token}) => {
      if (newPassword !== repeatPassword) {
        throw new Error('password mismatch');
      }

      const url = currentPassword ? '/api/auth/users/set_password/' : '/api/auth/users/reset_password_confirm/';
      try {
        const { data } = await axios.post(url, {
          uid, token, new_password: newPassword, current_password: currentPassword || undefined
        });
        return data;
      } catch (error) {
        const tokenErrors = error.response.data['token'];
        if (tokenErrors?.includes('Invalid token for given user.')) {
          throw new Error('invalid token');
        }

        const authErrors = error.response.data['current_password'];
        if (authErrors?.includes('Invalid password.')) {
          throw new Error('invalid current password')
        }

        const passwordErrors = error.response.data['new_password'];
        if (passwordErrors) {
          let keywords = "";
          if (passwordErrors.includes('This password is too short. It must contain at least 8 characters.')) {
            keywords += 'short password ';
          }
          if (passwordErrors.includes('This password is too common.')) {
            keywords += 'common password ';
          }
          if (passwordErrors.includes('This password is entirely numeric.')) {
            keywords += 'numeric password ';
          }
          if (passwordErrors.includes('The password is too similar to the email address.')) {
            keywords += 'similar email password';
          }
          throw new Error(keywords);
        }

        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );

  const handleCurrentPasswordChange = (e, { value }) => {
    setCurrentPassword(value);
    passwordMutation.reset();
  };
  const handleNewPasswordChange = (e, { value }) => {
    setNewPassword(value);
    passwordMutation.reset();
  };
  const handleRepeatPasswordChange = (e, { value }) => {
    setRepeatPassword(value);
    passwordMutation.reset();
  };

  const handleSubmit = () => passwordMutation.mutate({ currentPassword, newPassword, repeatPassword, uid, token});

  const handleClose = () => {
    let redirectUrl = token ? '/' : '/vendor/profile';

    if (passwordMutation.isSuccess) {
      redirectUrl = '/login';
      if (!token) {
        // means we change the password, let's send the user to the login and also logout them out
        redirectUrl += '?logout=1';
      }
    }
    history.push(redirectUrl);
  }

  return (
    <Modal centered={false} size="small" dimmer="inverted" open onClose={handleClose}>
      <Modal.Header>Αλλαγή συνθηματικού</Modal.Header>
      <Modal.Content>
        <Form id="password-reset-confirm" size="large"
          onSubmit={handleSubmit}
          success={passwordMutation.isSuccess}
          error={passwordMutation.isError}
        >
          <Form.Group grouped>
            { !token &&
              <Form.Input
                value={currentPassword}
                label="Ισχύον συνθηματικό"
                type="password"
                required
                onChange={handleCurrentPasswordChange}
                readOnly={passwordMutation.isSuccess}
              />
            }
            <Form.Input
              value={newPassword}
              label="Νέο συνθηματικό"
              type="password"
              required
              onChange={handleNewPasswordChange}
              readOnly={passwordMutation.isSuccess}
            />
            <Form.Input 
              value={repeatPassword}
              label="Επαλήθευση νέου συνθηματικού"
              type="password"
              required
              onChange={handleRepeatPasswordChange}
              readOnly={passwordMutation.isSuccess}
            />
          </Form.Group>
          
          <PasswordErrorMessage size="small" keywords={passwordMutation.error?.message} />

          <Message size="small" success
            header="Το συνθηματικό για τον λογαριασμό σας άλλαξε επιτυχώς"
            content="Μπορείτε τώρα να χρησιμοποίησετε το νέο συνθηματικό για να συνδεθείτε στην πλατφόρμα."
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        { !passwordMutation.isSuccess &&
          <Button form="password-reset-confirm" primary content="Αλλαγή συνθηματικού"
            loading={passwordMutation.isLoading}
            disabled={passwordMutation.isLoading}
          />
        }
        <Button
          primary={passwordMutation.isSuccess && true}
          content={passwordMutation.isSuccess ? 'Σύνδεση' : 'Επιστροφή'}
          onClick={handleClose}
        />
      </Modal.Actions>
    </Modal>
  );
}
