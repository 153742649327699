// eslint-disable-next-line no-unused-vars
import { useEffect } from 'react';
import { Container, Divider, Grid, Header, Segment, Statistic } from 'semantic-ui-react';

import PointsBalance from './PointsBalance';
import PointsRedeemed from './PointsRedeemed';

import useProfile from '../queries/useProfile';
import { Helmet } from 'react-helmet';


export default function ParticipantProfile({ refetchOnMount=true }) {
  const profile = useProfile();
  const participant = profile.data?.participant;

  // // on each mount, refresh the profile
  // useEffect(() => {
  //   if (refetchOnMount) profile.refetch();
  // }, [refetchOnMount]);

  const pageTitle = 'Λογαριασμός';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <Grid columns={2} stackable textAlign="center">
        <Grid.Column only="mobile" >
          <Header as="h2">Έχεις διαθέσιμους για εξαργύρωση</Header>
          <PointsBalance plain />
        </Grid.Column>
        <Grid.Column only="mobile">
          <Divider fitted />
        </Grid.Column>
        <Grid.Column only="mobile" >
          <Header as="h2">Έχεις χρησιμοποιήσει κουπόνια που αξιοποίησαν</Header>
          <PointsRedeemed plain />
        </Grid.Column>

        <Grid.Row only="computer tablet" verticalAlign="bottom">
          <Grid.Column>
            <Header as="h2">Έχεις διαθέσιμους για εξαργύρωση</Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h2">Έχεις χρησιμοποιήσει κουπόνια που αξιοποίησαν</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="computer tablet">
          <Grid.Column>
            <PointsBalance plain />
          </Grid.Column>
          <Grid.Column>
            <PointsRedeemed plain />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider fitted />
      <Divider fitted />
      <Divider hidden />

      <Segment basic textAlign="center">
        <Header as="h2" textAlign="center">Έχεις συγκεντρώσει συνολικά</Header>
        <Statistic color="green" label="πόντους" value={participant && (participant.points_cookingoils + participant.points_reuse)} />

        <Divider />

        <Statistic.Group size="small" widths={2} className="centered">
          <Statistic color="olive" label="από ανακύκλωση τηγανέλαιων" value={participant?.points_cookingoils} />
          <Statistic color="blue" label="από επαναχρησιμοποίηση" value={participant?.points_reuse} />
        </Statistic.Group>
      </Segment>

      <Divider hidden />
    </Container>
  </>);
}
