import { useCallback, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { Login, TokenProvider, useToken } from '@zerowaste/components';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import useProfile from './queries/useProfile';

import RewardsContainer from './components/RewardsContainer';
import RewardsFooter from './components/RewardsFooter';
import RewardsHome from './components/RewardsHome';
import RewardsVendorHome from './components/RewardsVendorHome';
import RewardsAdmin from './components/RewardsAdmin';
import VendorSignup from './components/VendorSignup';

import qs from 'qs';

import cookingOilsImg from './images/cookingoils-only.png';

const loginUrl = (returnTo, cancelTo) => `${process.env.REACT_APP_ZEROWASTE_LOGIN_URL}?${qs.stringify({
  destination: `/oauth/authorize/?${qs.stringify({
    client_id: process.env.REACT_APP_ZEROWASTE_CLIENT_ID,
    response_type: 'code',
    state: returnTo,
  })}`,
  cancelTo: cancelTo ?? window.location.href,
})}`;

const registerUrl = (returnTo, cancelTo) => `${process.env.REACT_APP_ZEROWASTE_REGISTER_URL}?${qs.stringify({
  destination: `/oauth/authorize/?${qs.stringify({
    client_id: process.env.REACT_APP_ZEROWASTE_CLIENT_ID,
    response_type: 'code',
    state: returnTo,
  })}`,
  cancelTo: cancelTo ?? window.location.href,
})}`;

const privatePageRegExp = /^\/(admin|vendor|profile|coupons|history)/;

function Rewards() {
  const history = useHistory();
  const location = useLocation();

  const { token, setToken } = useToken();

  // token clear logic
  const { logout: logoutOnMount } = qs.parse(location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (logoutOnMount) {
      // clear search string
      history.replace({
        search: '',
      });

      setToken(null);
    }
  }, [logoutOnMount, history, setToken]);

  const profile = useProfile();

  const handleLogin = (returnTo, cancelTo) => () => {
    if (!returnTo) returnTo = window.location.pathname;

    if (process.env.REACT_APP_ZEROWASTE_CLIENT_ID) {
      cancelTo &&= `${window.location.protocol}//${window.location.host}${cancelTo}`;
      window.location = loginUrl(returnTo, cancelTo);
    } else {
      history.push('/login?' + qs.stringify({returnTo}))
    }
  };

  const handleRegister = (returnTo, cancelTo) => () => {
    if (!returnTo) returnTo = window.location.pathname;
    cancelTo &&= `${window.location.protocol}//${window.location.host}${cancelTo}`;
    window.location = registerUrl(returnTo, cancelTo);
  };

  const handleLogout = useCallback(() => {
    // check if we are not in a private page
    const privatePage = privatePageRegExp.test(window.location.pathname);
    // go to home to avoid re-login
    if (privatePage) history.push('/');
    // logout
    setToken(null);

    if (process.env.REACT_APP_ZEROWASTE_CLIENT_ID) {
      const returnTo = privatePage ? `${window.location.protocol}//${window.location.host}/` : window.location.href;
      window.location = `${process.env.REACT_APP_ZEROWASTE_LOGOUT_URL}?${qs.stringify({ returnTo })}`;
    }

  }, [setToken, history]);

  useEffect(() => {
    profile.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <RewardsContainer onLogin={handleLogin('/profile', window.location.pathname)} onRegister={handleRegister('/profile')} onLogout={handleLogout} >
      <Helmet
        defaultTitle="Πλατφόρμα εξαργύρωσης πόντων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
        titleTemplate="%s | Πλατφόρμα εξαργύρωσης πόντων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
      >
        <meta property="og:locale" content="el_GR" />
        <meta property="og:site_name" content="Πλατφόρμα εξαργύρωσης πόντων &ndash; ΔΙΑΔΥΜΑ ΑΕ" />
        <meta property="og:image" content={cookingOilsImg} />
      </Helmet>

      <main>
        <Switch>
          <Route path="/login">
            <Helmet title="Σύνδεση στη διαχείριση" />
            <Login passwordReset="/password/reset" header="Σύνδεση στη διαχείριση εξαργύρωσης πόντων ΔΙΑΔΥΜΑ" />
          </Route>
          <Route path="/signup">
            <Helmet title="Εγγραφή καταστήματος" />
            <VendorSignup />
          </Route>
          <Route path="/vendor">
            <RewardsVendorHome />
          </Route>
          <Route path="/admin">
            <Helmet defaultTitle="Διαχείριση | Πλατφόρμα εξαργύρωσης πόντων &ndash; ΔΙΑΔΥΜΑ ΑΕ" />
            <RewardsAdmin />
          </Route>
          <Route>
            <RewardsHome onLogin={handleLogin} onRegister={handleRegister} />
          </Route>
        </Switch>
      </main>

      <RewardsFooter />
    </RewardsContainer>
  );
}

function App() {
  return (
    <TokenProvider>
      <Rewards />
    </TokenProvider>
  );
}

export default App;
