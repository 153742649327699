import { Segment, Header, Statistic } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';


function PointsBalance({ plain, label='Πόντους', ...props }) {
  const profile = useProfile();
  const participant = profile.data?.participant;

  if (!participant) return null;

  const statistic = (
    <Statistic {...props}>
      <Statistic.Value>{ participant.points_balance }</Statistic.Value>
      <Statistic.Label>{ label }</Statistic.Label>
    </Statistic>
  );

  return plain ? statistic : (
    <>
      <Header as="h2" textAlign="center">Έχεις διαθέσιμους για εξαργύρωση</Header>
      <Segment basic textAlign="center">
        { statistic }
      </Segment>
    </>
  );
}

export default PointsBalance;
